import { useEffect } from 'react';
import {
    useEditorBridge,
    HardBreakBridge,
    DropCursorBridge,
    ListItemBridge,
    PlaceholderBridge,
    LinkBridge,
    OrderedListBridge,
    ItalicBridge,
    StrikeBridge,
    BoldBridge,
    HistoryBridge,
    UnderlineBridge,
    BulletListBridge,
    CoreBridge,
} from '@10play/tentap-editor';
import { ViewStyle, StyleProp } from 'react-native';

export const useEditor = ({
    initialContent,
    containerStyle,
    placeholder,
    editable = true,
}: {
    initialContent: () => object;
    containerStyle: StyleProp<ViewStyle>;
    placeholder: string;
    editable?: boolean;
}) => {
    const editor = useEditorBridge({
        initialContent: initialContent(),
        dynamicHeight: true,
        theme: {
            webviewContainer: containerStyle,
        },
        editable,
        bridgeExtensions: [
            BoldBridge,
            HistoryBridge,
            ItalicBridge,
            StrikeBridge,
            UnderlineBridge,
            OrderedListBridge,
            BulletListBridge,
            LinkBridge,
            CoreBridge,
            PlaceholderBridge,
            ListItemBridge,
            DropCursorBridge,
            HardBreakBridge,
        ],
    });

    useEffect(() => {
        editor.setPlaceholder(placeholder);
    }, [editor, placeholder]);

    return editor;
};
