import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useGetApartmentDocs } from '../../../../../_api/useApartments';
import { WH } from '../../../../../_utils';
import { screenMargin, smallestMargin } from '../../../../../_utils/sizes';
import { ControlledInput, Icon, Loader } from '../../../../../Components';
import DocumentList from '../../../../../Components/DocumentList';

interface ApartmentDocumentsProps {
    aptId: number;
}

const ApartmentDocuments = ({ aptId }: ApartmentDocumentsProps): ReactElement => {
    const { t } = useTranslation();
    const { data, isLoading } = useGetApartmentDocs(aptId);
    const [searchedText, setSearchedText] = useState('');

    if (isLoading) {
        return (
            <View style={styles.loader}>
                <Loader />
            </View>
        );
    }
    return (
        <View style={styles.main}>
            <View style={styles.searchInput}>
                <ControlledInput
                    onChange={(arg) => setSearchedText(arg ?? '')}
                    value={searchedText}
                    placeholder={t('documents:search')}
                    leftImage={<Icon name="search" color="secondaryText" />}
                    containerStyle={{}}
                    rightImage={
                        searchedText ? (
                            <TouchableOpacity
                                onPress={() => setSearchedText('')}
                                pressRetentionOffset={{ top: 20, bottom: 20, right: 20, left: 40 }}
                                style={{ paddingRight: smallestMargin }}
                            >
                                <Icon name="cross" color="mediumGrey" />
                            </TouchableOpacity>
                        ) : undefined
                    }
                />
            </View>
            <DocumentList data={data} searchedText={searchedText} />
        </View>
    );
};

const styles = StyleSheet.create({
    main: {
        flex: 1,
        marginBottom: WH * 0.06,
    },
    loader: {
        marginTop: screenMargin,
    },
    searchInput: {
        marginLeft: screenMargin,
        marginRight: screenMargin,
        flexShrink: 0,
        marginTop: screenMargin,
    },
});

export default ApartmentDocuments;
