import { useMemo } from 'react';
import { isWeb } from '_utils/isPlatform';
import { useSettings } from '../../_api';
import { APP_VERSION } from '../../_constants';
import { captureException } from '../../_utils/Sentry';

const isNewerVersion = (value: string, comparable: string) => {
    const valueNumbers = value.split('.').map((curr) => parseInt(curr, 10));
    const comparableNumbers = comparable.split('.').map((curr) => parseInt(curr, 10));

    for (let index = 0; index < Math.min(valueNumbers.length, comparableNumbers.length); index++) {
        if (valueNumbers[index] > comparableNumbers[index]) {
            return true;
        } else if (valueNumbers[index] < comparableNumbers[index]) {
            return false;
        }
    }
    return false;
};

const useShouldUpdateApp = () => {
    const { data: settings } = useSettings();

    return useMemo(() => {
        try {
            if (!APP_VERSION || isWeb()) {
                return null;
            }
            if (settings?.app_version && isNewerVersion(settings.app_version, APP_VERSION)) {
                return 'force';
            }
            if (settings?.recommended_app_version && isNewerVersion(settings.recommended_app_version, APP_VERSION)) {
                return 'recommended';
            }
            return null;
        } catch (e: unknown) {
            captureException(e);
            return null;
        }
    }, [settings?.app_version, settings?.recommended_app_version]);
};

export default useShouldUpdateApp;
