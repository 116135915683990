import { useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { useGetAccessTokenHeader } from '_utils/Axios';
import safeParse from '_utils/safeParse';
import { useSelectedCoop } from 'SelectedCoop';
import { useOwnProfile } from './useProfile';

export const suggestions = [
    'coffee',
    'bbq',
    'quiz',
    'sport',
    'knitting',
    'walk',
    'bathing',
    'workout',
    'boardgames',
    'party',
    'community_effort',
    'common_dinner',
    'christmas_lighting',
    'other',
] as const;

const suggestionLiteral = z.enum(suggestions);

const dataSchema = z.union([z.array(z.never()).max(0), z.record(suggestionLiteral, z.array(z.number()))]);
const getActivityInterests = async (coopId: number, getAuthHeader: () => Promise<string>) => {
    const authHeader = await getAuthHeader();
    const response = await axios.get(`/cooperatives/${coopId}/activity_interests`, {
        headers: { authorization: authHeader },
    });
    return safeParse(response.data, dataSchema);
};

export type SuggestionType = (typeof suggestions)[number];

const registerInterest = async ({
    coopId,
    type,
    authHeader,
}: {
    coopId: number;
    type: SuggestionType;
    authHeader: string;
}) => {
    const response = await axios.post(
        `/cooperatives/${coopId}/activity_interests/register`,
        { type },
        {
            headers: { authorization: authHeader },
        },
    );
    if (!response.data) {
        throw new Error('No response data');
    }
};

const getQueryKey = (coopId: number) => ['activityInterests', coopId];
export const useActivityInterests = () => {
    const coopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: getQueryKey(coopId),
        queryFn: () => getActivityInterests(coopId, getAuthHeader),
        gcTime: Infinity,
        staleTime: 1000 * 60 * 60 * 24, // 1 day
    });
};

export const usePrefetchActivityInterests = () => {
    const coopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.prefetchQuery({
            queryKey: getQueryKey(coopId),
            queryFn: () => getActivityInterests(coopId, getAuthHeader),
        });
    }, [coopId, getAuthHeader, queryClient]);
};

export const useRegisterInterest = () => {
    const queryClient = useQueryClient();
    const coopId = useSelectedCoop();
    const ownId = useOwnProfile().data?.id;
    const getAuthHeader = useGetAccessTokenHeader();
    return useMutation({
        mutationFn: async (type: SuggestionType) => {
            const authHeader = await getAuthHeader();
            return registerInterest({ coopId, type, authHeader });
        },
        onMutate: async (type: SuggestionType) => {
            await queryClient.cancelQueries({ queryKey: ['activityInterests', coopId] });

            const previousInterests = queryClient.getQueryData(['activityInterests', coopId]);

            queryClient.setQueryData(['activityInterests', coopId], (old: unknown) => {
                const newInterests = (typeof old === 'object' && old !== null ? { ...old } : {}) as Record<
                    SuggestionType,
                    number[]
                >;
                if (!newInterests[type]) {
                    newInterests[type] = [];
                }
                newInterests[type].push(ownId ?? 0);
                return newInterests;
            });

            return { previousInterests };
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['activityInterests', coopId] });
        },
    });
};
