import React, { ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Theme, useThemeStyle } from '_utils';
import { screenMargin, smallestFontSize, smallestMargin, titleFontSize } from '_utils/sizes';
import HeimeText from 'Components/HeimeText';
import Icon from 'Components/Icon/Icon';

const DocumentItem = ({
    onPress,
    icon,
    title,
    desc,
    chevron,
}: {
    onPress: () => void;
    icon: ReactElement;
    title: string;
    desc: string;
    chevron: boolean;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <TouchableOpacity onPress={onPress} style={themedStyle.documentContainer}>
            {icon}
            <View style={{ flex: 1 }}>
                <HeimeText style={themedStyle.titleText}>{title}</HeimeText>
                <HeimeText style={themedStyle.sizeText}>{desc}</HeimeText>
            </View>
            {chevron && <Icon name="chevron" color="darkGrey" />}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        documentContainer: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            paddingTop: screenMargin,
            paddingBottom: screenMargin,
            alignItems: 'center',
            flexDirection: 'row',
            borderBottomColor: theme.lightGrey,
            borderBottomWidth: 1,
            gap: smallestMargin,
        },
        titleText: {
            fontWeight: 'bold',
            fontSize: titleFontSize,
            color: theme.main,
        },
        sizeText: {
            fontSize: smallestFontSize,
            color: theme.main,
        },
    });

export { DocumentItem };
