import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, TouchableOpacity, View } from 'react-native';
import { screenMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { InfoMessage, HeimeText } from 'Components';

const DisabledReportsMessage = ({ mail }: { mail: string }): ReactElement => {
    const { t } = useTranslation();
    const { theme } = useThemeContext();

    const handleDisableSupports = () => {
        Linking.openURL(`mailto:${mail}`);
    };

    return (
        <View style={{ margin: screenMargin }}>
            <InfoMessage
                message={
                    <HeimeText>
                        {t('reports:disabled')}
                        <TouchableOpacity onPress={handleDisableSupports}>
                            <HeimeText
                                style={{
                                    color: theme.hyperText,
                                    alignItems: 'flex-end',
                                    marginLeft: 5,
                                    marginTop: 5,
                                }}
                            >
                                {mail}
                            </HeimeText>
                        </TouchableOpacity>
                    </HeimeText>
                }
            />
        </View>
    );
};

export default DisabledReportsMessage;
