import React, { ReactElement, Fragment, PropsWithChildren, Dispatch, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, Keyboard, View, useColorScheme } from 'react-native';
import {
    DefaultKeyboardToolbarTheme,
    KeyboardProvider as KeyboardControllerProvider,
    KeyboardToolbar,
} from 'react-native-keyboard-controller';
import { HeimeText } from 'Components';
import createContextFactory from './createContextFactory';
import useKeyboardIsVisible from './hooks/useKeyboardIsVisible';
import { isAndroid, isiOS, isWeb } from './isPlatform';
import { smallestMargin } from './sizes';

const KeyboardContext = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const KeyboardComponent = isiOS() ? KeyboardControllerProvider : Fragment;

    return (
        <KeyboardComponent>
            <KeyboardProvider initialState={{ enabled: false }} context={{}}>
                <SubKeyboardContext>{children}</SubKeyboardContext>
            </KeyboardProvider>
        </KeyboardComponent>
    );
};

const SubKeyboardContext = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const { state } = useKeyboardContext();
    const { t } = useTranslation();

    const toolbarProps = {
        showArrows: state.showArrows,
        content: state.toolbar,
        doneText: t('global:keyboard_done'),
        opacity: 'EE' as const,
        button: state.showDone ? undefined : () => <></>,
    };

    return (
        <>
            {children}
            {state.enabled && !isWeb() ? (
                isAndroid() ? (
                    <CustomKeyboardToolbar {...toolbarProps} />
                ) : (
                    <KeyboardToolbar {...toolbarProps} />
                )
            ) : null}
        </>
    );
};

const CustomKeyboardToolbar = ({
    content,
    button,
    doneText,
    theme = DefaultKeyboardToolbarTheme,
}: ComponentProps<typeof KeyboardToolbar>) => {
    const keyboardIsVisible = useKeyboardIsVisible();
    const onDone = () => Keyboard.dismiss();
    const colorScheme = useColorScheme();
    const keyboardTheme = colorScheme === 'dark' ? theme.dark : theme.light;

    if (!keyboardIsVisible) {
        return null;
    }
    const doneButtton = button ? null : (
        <TouchableOpacity onPress={onDone} style={{ padding: smallestMargin }}>
            <HeimeText style={{ color: keyboardTheme.primary }}>{doneText}</HeimeText>
        </TouchableOpacity>
    );

    return (
        <View
            style={{
                position: 'absolute',
                bottom: 0,
                backgroundColor: keyboardTheme.background,
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
            }}
        >
            {content}
            {doneButtton}
        </View>
    );
};
type ToolbarState =
    | {
          enabled: false;
      }
    | {
          enabled: true;
          toolbar: ReactElement;
          showArrows: false;
          showDone: boolean;
      }
    | {
          enabled: true;
          showArrows: true;
      };

type ToolbarAction =
    | ({
          type: 'enableToolbar';
      } & (
          | {
                content: ReactElement;
                showDone: boolean;
                showArrows: false;
            }
          | { showArrows: true }
      ))
    | {
          type: 'disableToolbar';
      };

const { Provider: KeyboardProvider, useContextHook: useKeyboardContext } = createContextFactory(
    (state: ToolbarState, action: ToolbarAction) => {
        if (action.type === 'disableToolbar') {
            return { enabled: false } as const;
        }

        if (action.type === 'enableToolbar') {
            if (action.showArrows) {
                return { enabled: true, showArrows: true } as const;
            }
            return {
                enabled: true,
                toolbar: action.content,
                showArrows: action.showArrows,
                showDone: action.showDone,
            } as const;
        }

        return state;
    },
    (dispatch: Dispatch<ToolbarAction>) => ({
        enableToolbarArrows: () => dispatch({ type: 'enableToolbar', showArrows: true }),
        enableToolbarContent: (content: ReactElement, showDone: boolean) =>
            dispatch({ type: 'enableToolbar', content, showArrows: false, showDone }),
        disableToolbar: () => dispatch({ type: 'disableToolbar' }),
    }),
);

export { useKeyboardContext };

export default KeyboardContext;
