import { z } from 'zod';
import { captureException, addBreadcrumb } from './Sentry';

/**
 * This function parses the given value with the given schema, and returns the parsed value.
 * If we are in development mode, it will throw an error if the value does not match the schema. If not it will just report the error.
 * @param value - The value to be parsed.
 * @param schema - The Zod schema to parse the value with.
 * @returns The parsed value if successful, or the original value if an error occurs outside of development mode.
 */
export default function safeParse<T>(value: unknown, schema: z.ZodSchema<T, z.ZodTypeDef, unknown>): T {
    try {
        return schema.parse(value);
    } catch (e) {
        if (e instanceof z.ZodError) {
            const message = e.errors.map((val) => getZodMessage(val, value)).join('; ');
            console.error(message);
            addBreadcrumb('errorContext', message, 'error');
        }
        if (__DEV__) {
            throw e;
        } else {
            captureException(e);
            return value as T;
        }
    }
}

const getZodMessage = (issue: z.ZodIssue, value: unknown): string => {
    const path = issue.path.join('.');
    if (issue.code === 'invalid_union') {
        const findUnion = issue.unionErrors.find(
            (error) => !error.issues.some((innerIssue) => innerIssue.path[innerIssue.path.length - 1] === 'type'),
        );
        if (findUnion) {
            return findUnion.issues
                .map((innerIssue) =>
                    JSON.stringify({
                        message: innerIssue.message,
                        path: innerIssue.path.join('.'),
                        code: innerIssue.code,
                        value: getValueWithPath(value, innerIssue.path),
                        outerValue: getValueWithPath(value, innerIssue.path.splice(0, innerIssue.path.length - 1)),
                    }),
                )
                .join(', ');
        }
        const unionErrors = issue.unionErrors
            .map((unionError, index) => {
                return `Union member ${index + 1}, value:"${JSON.stringify(
                    getValueWithPath(value, issue.path),
                )}": ${unionError.errors.map(getZodMessage).join(', ')}`;
            })
            .join(' | ');
        return `${path}: Invalid union. ${unionErrors}`;
    } else {
        return `${path}: ${issue.message}`;
    }
};

const getValueWithPath = (obj: unknown, path: Array<string | number>): unknown => {
    return path.reduce((acc, key) => {
        if (acc && typeof acc === 'object') {
            if (key in acc) {
                return (acc as Record<string | number, unknown>)[key];
            }
        }
        return undefined;
    }, obj);
};
