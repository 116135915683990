import { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';

const useKeyboardIsVisible = (): boolean => {
    const [isVisible, setIsVisible] = useState(Keyboard.isVisible());
    useEffect(() => {
        const setTrue = () => {
            setIsVisible(true);
        };
        const willShow = Keyboard.addListener('keyboardWillShow', setTrue);
        const didShow = Keyboard.addListener('keyboardDidShow', setTrue);
        return () => {
            willShow.remove();
            didShow.remove();
        };
    }, []);

    useEffect(() => {
        const setFalse = () => {
            setIsVisible(false);
        };
        const willHide = Keyboard.addListener('keyboardWillHide', setFalse);
        const didHide = Keyboard.addListener('keyboardDidHide', setFalse);
        return () => {
            willHide.remove();
            didHide.remove();
        };
    }, []);

    return isVisible;
};

export default useKeyboardIsVisible;
