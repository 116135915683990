import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useGetUser } from '_api/useUsers';
import _fonts from '_fonts';
import Images from '_images';
import { fromNowFormat, useThemeStyle, WW } from '_utils';
import { useSelectedCoopItem } from '_utils/hooks';
import { getUsernameFromProfile } from '_utils/misc';
import { screenMargin, smallestFontSize, smallestMargin, subtitleFontSize, titleFontSize } from '_utils/sizes';
import { Theme, useThemeContext } from '_utils/themeContext';
import { CacheImage, ListItem, HeimeText, Icon } from 'Components';
import ReadMoreRichText from 'Components/RichText/ReadMoreRichText';
import { RichText } from 'types/Base';
import { UnixTimeCode } from 'types/Utility';
import ApartmentGroupsDisplay from '../../ApartmentGroupsDisplay';

interface RequestItemProps {
    title: string;
    content: RichText | null;
    creator: number;
    fromBoard?: boolean;
    created?: UnixTimeCode | null;
    isNew?: boolean;
    onPress: (expanded: boolean) => void;
    commentsLength: number;
    showComments: boolean;
    attachmentsLength: number;
    apartment_groups: { id: string; name: string }[];
}

const RequestItem = ({
    title,
    content,
    creator,
    fromBoard = false,
    created,
    isNew,
    onPress,
    commentsLength,
    showComments,
    attachmentsLength,
    apartment_groups,
}: RequestItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const user = useGetUser(creator);
    const coopType = useSelectedCoopItem()?.type ?? 'joint_ownership';

    const backgroundColor = fromBoard ? theme.lightGreen : undefined;

    return (
        <TouchableOpacity
            onPress={() => onPress(!showComments || commentsLength === 0)}
            style={[themedStyle.main, { backgroundColor }]}
        >
            <ApartmentGroupsDisplay apartment_groups={apartment_groups} />
            <HeimeText style={themedStyle.title}>{title}</HeimeText>
            {created && <Text style={themedStyle.statusText}>{fromNowFormat(moment.unix(created).toDate())}</Text>}
            {content ? (
                <ReadMoreRichText
                    onReadMorePress={() => {
                        onPress(true);
                        return false;
                    }}
                    numberOfLines={5}
                    text={content}
                />
            ) : null}
            {fromBoard ? (
                <View style={themedStyle.spacer} />
            ) : (
                <ListItem
                    containerStyle={themedStyle.userContainer}
                    containerWrapper={themedStyle.userContentWrapper}
                    avatar={<CacheImage source={user?.avatar ?? Images.defaultAvatar} style={themedStyle.avatar} />}
                    title={getUsernameFromProfile(user)}
                    titleStyle={themedStyle.userText}
                />
            )}
            {isNew || fromBoard || commentsLength || attachmentsLength ? (
                <View style={[themedStyle.rowContainer, themedStyle.tagsContainer]}>
                    {isNew ? (
                        <View
                            style={[
                                themedStyle.newContainer,
                                themedStyle.contentCenter,
                                fromBoard ? themedStyle.containerFromBoard : undefined,
                            ]}
                        >
                            <HeimeText style={[themedStyle.newText, fromBoard ? themedStyle.fromBoardText : undefined]}>
                                {t('sharingAll:new')}
                            </HeimeText>
                        </View>
                    ) : null}

                    {fromBoard ? (
                        <View
                            style={[
                                themedStyle.rowContainer,
                                themedStyle.newContainer,
                                themedStyle.contentCenter,
                                themedStyle.containerFromBoard,
                            ]}
                        >
                            <Icon name="board" color="mainText" />
                            <HeimeText
                                style={[themedStyle.newText, themedStyle.commentText, themedStyle.fromBoardText]}
                            >
                                {t('sharingAll:fromBoard', {
                                    boardNoun: t(`typeSpecific:${coopType}:boardNounDefiniteArticle`),
                                })}
                            </HeimeText>
                        </View>
                    ) : null}
                    {showComments && commentsLength ? (
                        <View style={[themedStyle.rowContainer, themedStyle.contentCenter]}>
                            <Icon name="comment" color="main" />
                            <HeimeText style={[themedStyle.newText, themedStyle.commentText]}>
                                {commentsLength === 1
                                    ? t('sharingAll:comment')
                                    : t('sharingAll:comments', { count: commentsLength })}
                            </HeimeText>
                        </View>
                    ) : null}
                    {attachmentsLength ? (
                        <View style={[themedStyle.rowContainer, themedStyle.contentCenter]}>
                            <Icon name="attachment" color="main" />
                            <HeimeText style={[themedStyle.newText, themedStyle.commentText]}>
                                {attachmentsLength === 1
                                    ? t('sharingAll:attachment')
                                    : t('sharingAll:attachments', { count: attachmentsLength })}
                            </HeimeText>
                        </View>
                    ) : null}
                </View>
            ) : null}
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            width: '100%',
            minHeight: screenMargin,
            paddingTop: screenMargin,
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            borderTopWidth: screenMargin / 2,
            borderTopColor: theme.lightBackground,
        },
        spacer: {
            height: screenMargin,
        },
        userContentWrapper: {
            justifyContent: 'center',
            marginLeft: WW * 0.04,
            marginRight: WW * 0,
        },
        userContainer: {
            borderBottomWidth: 0,
            paddingTop: smallestMargin,
            paddingBottom: 0,
        },
        userText: {
            fontSize: subtitleFontSize,
            color: theme.darkGray,
            fontFamily: _fonts.primaryFontBold,
        },
        avatar: {
            width: WW * 0.12,
            height: WW * 0.12,
            borderRadius: (WW * 0.12) / 2,
            overflow: 'hidden',
        },
        rowContainer: {
            flexDirection: 'row',
            alignItems: 'stretch',
            flexWrap: 'wrap',
        },
        fromBoardContainer: {
            alignSelf: 'flex-start',
            borderRadius: WW * 0.04,
            backgroundColor: theme.lightGreen,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        fromBoardText: {
            fontFamily: _fonts.primaryFont,
            color: theme.mainText,
            fontSize: smallestFontSize,
        },
        title: {
            color: theme.black,
            fontWeight: 'bold',
            fontSize: titleFontSize,
        },

        statusText: {
            fontFamily: _fonts.primaryFont,
            color: theme.greyMedium,
            fontSize: smallestFontSize,
            marginBottom: smallestMargin,
        },
        content: {
            fontFamily: _fonts.primaryFont,
            color: theme.black,
            fontSize: subtitleFontSize,
            lineHeight: subtitleFontSize * 1.5,
            flex: 1,
        },
        newContainer: {
            backgroundColor: theme.lightGreen,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
        },
        contentCenter: {
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            display: 'flex',
            borderRadius: WW * 0.04,
            marginRight: smallestMargin,
            paddingTop: WW * 0.01,
            paddingBottom: WW * 0.01,
        },
        containerFromBoard: {
            backgroundColor: theme.main,
        },
        newText: {
            fontSize: smallestFontSize,
            color: theme.main,
        },
        tagsContainer: {
            flexWrap: 'wrap',
            marginBottom: smallestMargin,
        },
        commentText: {
            marginLeft: smallestMargin,
        },
    });

export default RequestItem;
