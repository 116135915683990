import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, SectionList } from 'react-native';
import { z } from 'zod';
import { useOwnProfile } from '_api/useProfile';
import { useEditReservationUsers } from '_api/useReservations';
import { useGetUser, useGetUsers } from '_api/useUsers';
import { SafeAreaView } from '_dependencies/safeArea';
import Images from '_images';
import { useAppNavigation } from '_navigator';
import { Theme, useThemeStyle, useNeighbourSearch, WW, useConfirmDiscard, showToast } from '_utils';
import { track } from '_utils/Amplitude';
import { useBottomSpacer } from '_utils/hooks';
import safeParse from '_utils/safeParse';
import { screenMargin, smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import {
    CacheImage,
    CheckBox,
    EmptyList,
    HeaderWithNav,
    HeimeText,
    Icon,
    Loader,
    PrimaryButton,
    QueryItemView,
    SectionHeader,
    UserListItem,
} from 'Components';
import ControlledInput from 'Components/ControlledInput';
import { OwnReservation } from 'types/Reservation';
import { ExternalUserProfile } from 'types/User';
import { RoutePropNumber, RoutePropsSchema } from 'types/Utility';
import FindReservation from './components/FindReservation';

const propsSchema = RoutePropsSchema(
    z.object({
        reservationId: RoutePropNumber,
    }),
);

const EditReservationUsers = (props: z.infer<typeof propsSchema>): ReactElement => {
    const {
        route: {
            params: { reservationId },
        },
    } = safeParse(props, propsSchema);
    return (
        <FindReservation reservationId={reservationId}>
            {({ reservation }) => <EditReservationUsersConfirmed reservation={reservation} />}
        </FindReservation>
    );
};

const EditReservationUsersConfirmed = ({ reservation }: { reservation: OwnReservation }) => {
    const themedStyle = useThemeStyle(styles);
    const { data: ownProfile } = useOwnProfile();
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const { data, isLoading, isError, error, refetch, isRefetching, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useGetUsers(false, true);

    const initialState = reservation.users.reduce(
        (acc, user) => {
            acc[user] = true;
            return acc;
        },
        {} as Record<number, boolean>,
    );

    const { filteredData, handleSearchTextChange, searchString } = useNeighbourSearch(data, 'alphabetic', false);
    const [usersState, setSelectedUsers] = useState<Record<number, boolean>>(initialState);

    const { mutate: editUsers, isPending: isEditingUsers } = useEditReservationUsers();
    const { goBack: internalGoBack } = useAppNavigation();
    const isChanged = JSON.stringify(initialState) !== JSON.stringify(usersState);

    const { content } = useConfirmDiscard(isChanged && !isEditingUsers, true, internalGoBack);

    if (isError) {
        throw error;
    }

    const handleContinueClick = () => {
        const selectedUsers = Object.entries(usersState)
            .filter(([_, isSelected]) => isSelected)
            .map(([item]) => parseInt(item, 10));

        track('Continue reservation users pressed');

        editUsers([reservation.booked.id, reservation.id, { users: selectedUsers }], {
            onSuccess: () => {
                showToast({
                    header: t('expandedReservation:users_edited'),
                    text: '',
                    type: 'success',
                });
                internalGoBack();
            },
        });
    };

    type ExternalUserProfileWithName = ExternalUserProfile & { name: string };
    const { roommates, neighbors } = useMemo((): {
        roommates: ExternalUserProfileWithName[];
        neighbors: typeof filteredData;
    } => {
        const ownApartments = ownProfile?.cooperative_apartments ?? [];

        if (searchString) {
            return {
                roommates: [],
                neighbors: filteredData,
            };
        } else {
            const filtered = filteredData.reduce(
                (innerAcc, { title, data: users }) => {
                    const dividedUsers = users.reduce(
                        (acc, user) => {
                            if (
                                ownApartments.some((apartment) =>
                                    user.cooperative_apartments?.some(
                                        (innerAp) =>
                                            innerAp.cooperative_id === apartment.cooperative_id &&
                                            innerAp.id === apartment.id,
                                    ),
                                )
                            ) {
                                acc.roommates.push(user);
                            } else {
                                acc.neighbors.push(user);
                            }
                            return acc;
                        },
                        { roommates: [], neighbors: [] } as {
                            roommates: ExternalUserProfileWithName[];
                            neighbors: ExternalUserProfileWithName[];
                        },
                    );
                    return {
                        roommates: [...dividedUsers.roommates, ...innerAcc.roommates],
                        neighbors: [
                            ...innerAcc.neighbors,
                            {
                                title,
                                data: dividedUsers.neighbors,
                            },
                        ],
                    };
                },
                { roommates: [], neighbors: [] } as {
                    roommates: ExternalUserProfileWithName[];
                    neighbors: typeof filteredData;
                },
            );
            return {
                roommates: filtered.roommates,
                neighbors: filtered.neighbors.filter(({ data: users }) => users.length > 0),
            };
        }
    }, [ownProfile?.cooperative_apartments, searchString, filteredData]);

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    const getHandleSelectUser = (userId: number) => () => {
        setSelectedUsers((currentUsers) => {
            if (currentUsers[userId]) {
                delete currentUsers[userId];
            } else {
                currentUsers[userId] = true;
            }
            return { ...currentUsers };
        });
    };

    const renderItem = useCallback(
        ({ item }: { item: ExternalUserProfile & { name: string } }) => {
            return (
                <TouchableOpacity key={item.id} onPress={getHandleSelectUser(item.id)}>
                    <UserListItem
                        title={item.name}
                        id={item.id}
                        actionButton={<CheckBox onPress={getHandleSelectUser(item.id)} checked={usersState[item.id]} />}
                        containerStyle={{
                            marginLeft: screenMargin,
                            marginRight: screenMargin,
                        }}
                        disableProfileNavigate
                    />
                </TouchableOpacity>
            );
        },
        [usersState],
    );

    const bottomPadding = useBottomSpacer();

    const selectedUsers = Object.entries(usersState)
        .filter(([_, isSelected]) => isSelected)
        .map(([id]) => parseInt(id, 10));

    return (
        <SafeAreaView
            edges={['top', 'left', 'right']}
            style={[themedStyle.container, { paddingBottom: bottomPadding }]}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <HeaderWithNav title={reservation.booked.name} />
                    <QueryItemView
                        onRefresh={refetch}
                        isRefreshing={isRefetching}
                        containerStyle={{
                            paddingHorizontal: screenMargin,
                        }}
                        virtualized
                        style={{ flex: 1 }}
                        keyboardShouldPersistTaps="handled"
                    >
                        <HeimeText style={{ textAlign: 'left', marginBottom: smallestMargin }} variant="title">
                            {t('expandedReservation:edit_users')}
                        </HeimeText>
                        <HeimeText style={{ marginBottom: smallestMargin }} variant="subtitle">
                            {t('expandedReservation:edit_users_subtitle')}
                        </HeimeText>
                        <View
                            style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: smallestMargin,
                                marginBottom: screenMargin,
                                borderWidth: 1,
                                borderColor: theme.mediumBackground,
                                padding: smallestMargin,
                            }}
                        >
                            {selectedUsers.map((item) => (
                                <SelectedUser
                                    key={item}
                                    id={item}
                                    onRemove={ownProfile?.id === item ? undefined : getHandleSelectUser(item)}
                                />
                            ))}
                        </View>
                        <ControlledInput
                            leftImage={<Icon name="search" color="secondaryText" />}
                            onChange={handleSearchTextChange}
                            value={searchString}
                            containerStyle={themedStyle.inputContainer}
                            placeholder={t('chat:search')}
                        />
                        {roommates.length > 0 ? (
                            <>
                                <HeimeText style={{ textAlign: 'left', marginBottom: smallestMargin }} variant="title">
                                    {t('expandedReservation:roommates')}
                                </HeimeText>
                                {roommates.map((item) => renderItem({ item }))}
                            </>
                        ) : null}
                        <HeimeText style={{ textAlign: 'left', marginBottom: smallestMargin }} variant="title">
                            {t('expandedReservation:neighbours')}
                        </HeimeText>
                        <SectionList
                            sections={neighbors}
                            renderItem={renderItem}
                            renderSectionHeader={({ section: { title } }) => <SectionHeader title={title} />}
                            stickySectionHeadersEnabled
                            ListEmptyComponent={
                                <EmptyList
                                    message={
                                        searchString
                                            ? t('expandedReservation:noUsers')
                                            : t('expandedReservation:noUsersToAdd')
                                    }
                                />
                            }
                        />
                    </QueryItemView>

                    <PrimaryButton
                        onPress={handleContinueClick}
                        text={t('expandedReservation:continue')}
                        bottomAction
                        status={isEditingUsers ? 'loading' : isChanged ? null : 'disabled'}
                    />
                </>
            )}
            {content}
        </SafeAreaView>
    );
};

const SelectedUser = ({ id, onRemove }: { id: number; onRemove: undefined | (() => void) }) => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const user = useGetUser(id);
    return (
        <View
            style={{
                flexDirection: 'column',
                alignItems: 'center',
                gap: smallestMargin,
                position: 'relative',
                paddingRight: WW * 0.02,
                width: WW * 0.2,
            }}
        >
            {onRemove ? (
                <TouchableOpacity
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        backgroundColor: theme.mediumBackground,
                        zIndex: 1,
                        borderRadius: WW * 0.15,
                    }}
                    onPress={onRemove}
                    accessibilityLabel={t('expandedReservation:remove_user')}
                >
                    <Icon name="cross" color="black" />
                </TouchableOpacity>
            ) : null}
            <CacheImage
                style={{
                    marginTop: WW * 0.02,
                    width: WW * 0.15,
                    height: WW * 0.15,
                    borderRadius: WW * 0.15,
                    overflow: 'hidden',
                }}
                source={user?.avatar ?? Images.defaultAvatar}
            />
            <HeimeText>{user?.fname}</HeimeText>
            <HeimeText>{user?.lname}</HeimeText>
        </View>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: { display: 'flex', flex: 1, backgroundColor: theme.mainBackground, height: '100%' },
        inputContainer: { flex: 1, flexGrow: 1, marginBottom: screenMargin },
        topContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: screenMargin,
            marginRight: screenMargin,
        },
        allButton: { paddingTop: 0, paddingBottom: 0, borderRadius: 5 },
    });

export default EditReservationUsers;
