import React, { Component, ReactElement } from 'react';
import linkifyit from 'linkify-it';
import mdurl from 'mdurl';
import { Text, Linking, Platform, TextProps, ViewProps } from 'react-native';

// This file is a "fork" of the original Hyperlink component from react-native-hyperlink
// The original can be found here: https://github.com/obipawan/react-native-hyperlink/blob/master/src/Hyperlink.tsx

export type ReactElementWithType = ReactElement & {
    type?: { displayName?: string };
};

const linkify = new linkifyit();

export type HyperlinkProps = {
    linkDefault?: boolean;
    linkStyle?: TextProps['style'];
    linkText?: ((url: string) => string) | string;
    onPress?: (url: string, text?: string) => void;
    onLongPress?: (url: string, text?: string) => void;
    injectViewProps?: (url: string) => TextProps;
    style?: ViewProps['style'];
    children?: ReactElementWithType;
};

const { OS } = Platform;

class Hyperlink extends Component<HyperlinkProps> {
    public static defaultProps: Partial<HyperlinkProps> = {
        injectViewProps: (_) => ({}),
    };

    constructor(props: HyperlinkProps) {
        super(props);
    }

    render() {
        return !this.props.onPress && !this.props.onLongPress && !this.props.linkStyle
            ? this.props.children
            : //@ts-ignore
              this.parse(this).props.children;
    }

    isTextNested(component: ReactElementWithType) {
        if (!React.isValidElement(component)) {
            throw new Error('Invalid component');
        }
        let { type: { displayName } = {} } = component;
        if (displayName !== 'Text') {
            throw new Error('Not a Text component');
        }
        return typeof component.props.children !== 'string';
    }

    linkify = (component: ReactElementWithType) => {
        if (!linkify.pretest(component.props.children) || !linkify.test(component.props.children)) {
            return component;
        }

        let elements = [];
        let _lastIndex = 0;

        const componentProps = {
            ...component.props,
            ref: undefined,
            key: undefined,
        };

        try {
            linkify.match(component.props.children)?.forEach(({ index, lastIndex, text, url }) => {
                let nonLinkedText = component.props.children.substring(_lastIndex, index);
                nonLinkedText && elements.push(nonLinkedText);
                _lastIndex = lastIndex;
                if (this.props.linkText) {
                    text = typeof this.props.linkText === 'function' ? this.props.linkText(url) : this.props.linkText;
                }

                const clickHandlerProps: {
                    onPress?: HyperlinkProps['onPress'];
                    onLongPress?: HyperlinkProps['onLongPress'];
                } = {};
                if (OS !== 'web') {
                    clickHandlerProps.onLongPress = this.props.onLongPress
                        ? () => this.props.onLongPress?.(url, text)
                        : undefined;
                }
                clickHandlerProps.onPress = this.props.onPress ? () => this.props.onPress?.(url, text) : undefined;

                elements.push(
                    <Text
                        {...componentProps}
                        {...clickHandlerProps}
                        key={url + index}
                        style={[component.props.style, this.props.linkStyle]}
                        {...this.props.injectViewProps?.(url)}
                    >
                        {text}
                    </Text>,
                );
            });
            elements.push(component.props.children.substring(_lastIndex, component.props.children.length));
            return React.cloneElement(component, componentProps, elements);
        } catch (err) {
            return component;
        }
    };

    parse = (component: ReactElementWithType): ReactElementWithType => {
        let { props: { children } = { children: undefined } } = component || {};
        if (!children) {
            return component;
        }

        const componentProps = {
            ...component.props,
            ref: undefined,
            key: undefined,
        };

        return React.cloneElement(
            component,
            componentProps,
            React.Children.map(children, (child: ReactElementWithType) => {
                let { type: { displayName } = { displayName: undefined } } = child || {};
                if (typeof child === 'string' && linkify.pretest(child)) {
                    return this.linkify(
                        <Text {...componentProps} style={component.props.style}>
                            {child}
                        </Text>,
                    );
                }
                if (displayName === 'Text' && !this.isTextNested(child)) {
                    return this.linkify(child);
                }
                return this.parse(child);
            }),
        );
    };
}

export default class extends Component<HyperlinkProps> {
    constructor(props: HyperlinkProps) {
        super(props);
        this.handleLink = this.handleLink.bind(this);
    }

    handleLink(url: string) {
        const urlObject = mdurl.parse(url);
        urlObject.protocol = urlObject.protocol.toLowerCase();
        const normalizedURL = mdurl.format(urlObject);

        Linking.canOpenURL(normalizedURL).then((supported) => supported && Linking.openURL(normalizedURL));
    }

    render() {
        const onPress = this.handleLink || this.props.onPress;
        return this.props.linkDefault ? <Hyperlink {...this.props} onPress={onPress} /> : <Hyperlink {...this.props} />;
    }
}
