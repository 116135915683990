import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text } from 'react-native';
import { useGetProduct } from '_api/useCategories';
import { useAbortReservation } from '_api/useReservations';
import { SafeAreaView } from '_dependencies/safeArea';
import { useAppNavigation, useContextMenuFocusEffect } from '_navigator';
import { Theme, useThemeStyle, WH, WW, useRerenderCountdown, showToast } from '_utils';
import { screenMargin, smallestMargin } from '_utils/sizes';
import {
    BottomSpacer,
    ContextMenu,
    ContextTrigger,
    FullWidthPictureCarousel,
    HeaderWithNav,
    HeimeText,
    Modal,
    QueryItemView,
    SecondaryButton,
} from 'Components';
import { ProductHasPrice } from 'types/Category';
import { OwnReservation } from 'types/Reservation';
import CancelReservationModal from './CancelReservationModal';
import FindReservation from './components/FindReservation';
import ManageButtons from './components/ManageButtons';
import LockButtons from './LockButtons';
import ReservationDetail from './ReservationDetail';
import { useTimeSync } from '../../../TimeSyncContext';

interface ReservationSelectedProps {
    route: { params?: { id: string; initiallyUnlockLock: boolean } };
}

const ReservationSelected = ({ route }: ReservationSelectedProps): ReactElement => {
    useContextMenuFocusEffect(['reservations']);
    return (
        <FindReservation reservationId={parseInt(route?.params?.id ?? '', 10)}>
            {({ reservation, refetch, isRefetching }) => (
                <ReservationSelectedConfirmed
                    refetch={refetch}
                    isRefetching={isRefetching}
                    reservation={reservation}
                    initiallyUnlockLock={route.params?.initiallyUnlockLock ?? false}
                />
            )}
        </FindReservation>
    );
};

const ReservationSelectedConfirmed = ({
    reservation,
    initiallyUnlockLock,
    refetch,
    isRefetching,
}: {
    reservation: OwnReservation;
    initiallyUnlockLock: boolean;
    refetch: () => void;
    isRefetching: boolean;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const { goBack, push, navigate } = useAppNavigation();
    const { mutate: abortReservation } = useAbortReservation();
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const { status: timeSyncStatus } = useTimeSync();

    const handleContextMenuTrigger = () => setContextMenuOpen((prev) => !prev);

    const handleGoToUpdate = () =>
        push('ReservationEdited', {
            productId: reservation.booked.id,
            existingReservation: reservation,
            initialDate: reservation.start_at,
            skipPayment: reservation.skip_payment,
        });

    const handleCancelReservation = () => {
        abortReservation(
            { productId: reservation.booked.id, bookingId: reservation.id },
            {
                onSuccess: () => {
                    setCancelModalOpen(false);
                    goBack();
                    setTimeout(() => {
                        showToast({
                            header: t('expandedReservation:successCancelHeader'),
                            text: '',
                            type: 'success',
                        });
                    }, 0);
                },
                onError: () => {
                    showToast({ header: t('expandedReservation:errorCancel'), text: '', type: 'error' });
                },
            },
        );
    };

    const isInFuture = moment().isBefore(moment.unix(reservation.start_at));
    const isInPast = moment().isAfter(moment.unix(reservation.end_at));
    const isNow = !isInFuture && !isInPast;
    useEffect(() => {
        // IF we are missing the danalock tokens we need to refresh data
        if (isRefetching || isInFuture || isInPast) {
            return;
        }
        if (
            reservation.booked.locks?.find((item) => {
                if (item.type === 'danalock' || item.type === 'danalockRelay') {
                    return !item.token || (item.has_pin && !item.pin);
                }
                return false;
            })
        ) {
            refetch();
        }
    }, [isInFuture, isInPast, isRefetching, refetch, reservation.booked.locks]);

    const handleCancelPress = () => setCancelModalOpen(true);
    const handleCloseCancelModal = () => setCancelModalOpen(false);

    const handleCheckIn = () => {
        if (reservation.check_in) {
            navigate('Procedure', {
                bookingId: reservation.id,
                procedureId: reservation.check_in,
                product_id: reservation.booked.id + '',
            });
        }
    };
    const handleCheckOut = () => {
        if (reservation.check_out) {
            navigate('Procedure', {
                bookingId: reservation.id,
                procedureId: reservation.check_out,
                product_id: reservation.booked.id + '',
            });
        }
    };

    const { data: product } = useGetProduct(reservation.booked.id, undefined);
    const locks = (reservation.booked?.locks ?? []).filter(Boolean);

    const secondsUntilReservationEnd = moment.unix(reservation.end_at).diff(moment().startOf('minute'), 'seconds');

    useRerenderCountdown(
        isNow ? moment.unix(reservation.end_at) : moment.unix(reservation.start_at),
        locks.length === 1 || isInFuture,
    );

    const shouldCheckIn = isNow && reservation.check_in && !reservation.has_checked_in && !reservation.has_checked_out;
    const canCheckOut =
        isNow &&
        reservation.check_out &&
        !reservation.has_checked_out &&
        (reservation.has_checked_in || !reservation.check_in);

    return (
        <SafeAreaView edges={['top', 'left', 'right']} style={themedStyle.main}>
            <HeaderWithNav
                title={reservation.booked.name}
                action={
                    isNow && locks.length > 0 ? (
                        <ContextMenu
                            isOpen={contextMenuOpen}
                            onToggle={handleContextMenuTrigger}
                            actions={[
                                {
                                    text: t('expandedReservation:change'),
                                    onPress: () => {
                                        handleGoToUpdate();
                                        handleContextMenuTrigger();
                                    },
                                    type: 'normal',
                                },
                                canCheckOut
                                    ? {
                                          text: t('expandedReservation:checkOut'),
                                          onPress: () => {
                                              handleContextMenuTrigger();
                                              handleCheckOut();
                                          },
                                      }
                                    : {
                                          text: t('expandedReservation:cancel'),
                                          onPress: () => {
                                              handleContextMenuTrigger();
                                              handleCancelPress();
                                          },
                                          type: 'danger',
                                      },
                                {
                                    text: t('expandedReservation:edit_users'),
                                    onPress: () => {
                                        handleContextMenuTrigger();
                                        navigate('EditReservationUsers', { reservationId: reservation.id });
                                    },
                                    type: 'normal',
                                },
                            ]}
                            trigger={<ContextTrigger variant="light" />}
                        />
                    ) : undefined
                }
            />
            <QueryItemView isRefreshing={isRefetching} onRefresh={refetch} style={themedStyle.scrollView}>
                <FullWidthPictureCarousel
                    pictures={product?.pictures ?? (reservation.booked.picture ? [reservation.booked.picture] : [])}
                />
                <View style={[themedStyle.scrollView, themedStyle.container]}>
                    <SecondaryButton
                        onPress={() => navigate('ProductDetail', { productId: reservation.booked.id })}
                        text={t('expandedReservation:productInfo')}
                    />
                    <HeimeText variant="title" style={themedStyle.title}>
                        {product?.name ?? reservation.booked.name}
                    </HeimeText>
                    <ReservationDetail {...reservation} />
                </View>
            </QueryItemView>
            {shouldCheckIn || (reservation.check_out && reservation.has_checked_out) ? null : (
                <LockButtons
                    locks={reservation.booked.locks ?? []}
                    isInFuture={isInFuture}
                    isInPast={isInPast}
                    startTime={moment.unix(reservation.start_at).toDate()}
                    initiallyUnlockLock={initiallyUnlockLock && !timeSyncStatus}
                    secondsUntilReservationEnd={secondsUntilReservationEnd}
                />
            )}

            <ManageButtons
                status={
                    isInPast || reservation.has_checked_out
                        ? 'checkedOut'
                        : shouldCheckIn
                          ? 'shouldCheckIn'
                          : canCheckOut
                            ? locks.length === 0
                                ? 'canCheckOutEditable'
                                : 'canCheckOut'
                            : 'editable'
                }
                compact={locks.length > 0}
                isInFuture={isInFuture}
                onCheckIn={handleCheckIn}
                onCheckOut={handleCheckOut}
                onUpdate={handleGoToUpdate}
                onCancel={handleCancelPress}
            />

            {cancelModalOpen ? (
                isInFuture ? (
                    <CancelReservationModal
                        onDismiss={handleCloseCancelModal}
                        onCancel={handleCancelReservation}
                        refundable={reservation.booked.refundable}
                        refund_hours_before={reservation.booked.refund_hours_before ?? 0}
                        isShortTerm={reservation.booked.booking_time}
                        start_time={reservation.start_at}
                        hasPaid={ProductHasPrice(reservation.booked)}
                    />
                ) : (
                    <Modal
                        onDismiss={handleCloseCancelModal}
                        header={t('expandedReservation:cannotCancel')}
                        content={
                            <Text style={themedStyle.cancelModalExplainer}>{t('expandedReservation:noCancel')}</Text>
                        }
                        buttons={[
                            {
                                onPress: handleCloseCancelModal,
                                text: t('expandedReservation:okNoCancel'),
                                type: 'secondary',
                            },
                        ]}
                    />
                )
            ) : null}
            <BottomSpacer />
        </SafeAreaView>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        main: {
            height: '100%',
            display: 'flex',
            backgroundColor: theme.mainBackground,
        },
        image: {
            width: '100%',
            height: WH * 0.3,
            marginBottom: WW * 0.02,
        },

        bottomButtonsContainer: {
            flexGrow: 0,
            gap: smallestMargin,
        },
        container: {
            paddingLeft: screenMargin,
            paddingRight: screenMargin,
            display: 'flex',
        },

        scrollView: {
            paddingTop: smallestMargin,
            paddingBottom: WW * 0.04,
            flexGrow: 1,
        },
        cancelModalExplainer: { fontSize: WH * 0.0175 },
        title: { marginTop: WW * 0.04, marginBottom: WW * 0.02, textAlign: 'left' },
    });

export default ReservationSelected;
