import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { Text, StyleSheet, StyleProp, TextStyle, LayoutChangeEvent } from 'react-native';
import { useThemeStyle, Theme } from '_utils';
import { subtitleFontSize, subtitleLineHeight, titleFontSize } from '_utils/sizes';
import HyperLink from './Hyperlink';
import _fonts from '../_fonts';
import { ThemeContext } from '../_utils/themeContext';

interface HeimeTextProps {
    selectable?: boolean;
    linkify?: boolean;
    style?: StyleProp<TextStyle>;
    numberOfLines?: number;
    onLayout?: (event: LayoutChangeEvent) => void;
    maxFontSizeMultiplier?: number;
    variant?: 'title' | 'subtitle';
    onPress?: () => void;
}

const HeimeText = ({
    selectable = true,
    children,
    linkify,
    style,
    numberOfLines,
    onLayout,
    maxFontSizeMultiplier,
    variant,
    onPress,
}: PropsWithChildren<HeimeTextProps>): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const content = (
        <Text
            style={[
                themedStyle.text,
                variant === 'subtitle' ? themedStyle.subtitle : variant === 'title' ? themedStyle.title : undefined,
                style,
            ]}
            selectionColor={theme.lightGreen}
            numberOfLines={numberOfLines}
            selectable={selectable}
            onLayout={onLayout}
            maxFontSizeMultiplier={maxFontSizeMultiplier}
            onPress={onPress}
        >
            {children}
        </Text>
    );
    if (linkify) {
        return (
            <HyperLink linkStyle={{ color: theme.hyperText }} linkDefault={true}>
                {content}
            </HyperLink>
        );
    }

    return content;
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        text: { fontFamily: _fonts.primaryFont },
        title: {
            fontSize: titleFontSize,
            fontWeight: 'bold',
            textAlign: 'center',
            color: theme.black,
        },
        subtitle: {
            fontSize: subtitleFontSize,
            lineHeight: subtitleLineHeight,
            color: theme.mediumGrey,
        },
    });
export default HeimeText;
