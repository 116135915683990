import React, { ComponentProps, ComponentType, ReactElement, useContext } from 'react';
import {
    View,
    TextInput as RNTextInput,
    StyleSheet,
    TextInputProps,
    StyleProp,
    ViewStyle,
    Platform,
    DimensionValue,
} from 'react-native';
import { borderRadius, borderWidth, fontSize, padding, useInputPropColors } from './Form/formStyle';
import HeimeText from './HeimeText';
import InputLabel from './InputLabel';
import _fonts from '../_fonts';
import { useThemeStyle, WW, Theme, isWeb } from '../_utils';
import { smallestFontSize, smallestMargin } from '../_utils/sizes';
import { ThemeContext } from '../_utils/themeContext';

interface ControlledInputProps extends Omit<ComponentProps<typeof RNTextInput>, 'onChange'> {
    label?: string;
    multiline?: boolean;
    numberOfLines?: number;
    autoFocus?: boolean;
    optional?: boolean;
    optionalText?: string;
    maxLength?: number;
    leftImage?: ReactElement | null;
    rightImage?: ReactElement | null;
    placeholder?: TextInputProps['placeholder'];
    autoCorrect?: TextInputProps['autoCorrect'];
    autoCapitalize?: TextInputProps['autoCapitalize'];
    onTouchStart?: TextInputProps['onTouchStart'];
    onBlur?: TextInputProps['onBlur'];
    onFocus?: TextInputProps['onFocus'];
    editable?: TextInputProps['editable'];
    type?: 'number' | 'nameGiven' | 'familyName' | 'email';
    containerStyle?: StyleProp<ViewStyle>;
    value?: string;
    onChange(change: string | undefined): void;
    suffix?: string;
    inputComponent?: ComponentType<ComponentProps<typeof RNTextInput>>;
}

const ControlledInput = ({
    value,
    onChange,
    label,
    autoCapitalize = 'sentences',
    autoCorrect = true,
    type,
    containerStyle,
    suffix,
    optionalText,
    inputComponent: InputComponent = RNTextInput,
    ...textProps
}: ControlledInputProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { theme } = useContext(ThemeContext);
    const themedTextProps = useInputPropColors();

    return (
        <View style={[themedStyle.container, containerStyle]}>
            {label ? (
                <View style={themedStyle.row}>
                    <InputLabel optional={textProps.optional} optionalText={optionalText}>
                        {label}
                    </InputLabel>
                </View>
            ) : null}
            <View style={themedStyle.inputContainer}>
                <View
                    style={[
                        themedStyle.contentContainer,

                        {
                            paddingLeft: textProps.leftImage ? smallestMargin : WW * 0.0,
                            paddingRight: textProps.leftImage ? (textProps.leftImage ? 0 : smallestMargin) : 0.0,
                            borderColor: value ? theme.main : theme.secondaryLight,
                            borderWidth,
                        },
                        suffix ? themedStyle.inputWithSuffixContainer : undefined,
                    ]}
                >
                    {textProps.leftImage}
                    <InputComponent
                        style={[
                            platformStyles,
                            themedStyle.input,
                            textProps.multiline
                                ? textProps.numberOfLines
                                    ? [
                                          themedStyle.inputMultiline,
                                          { minHeight: fontSize * (textProps.numberOfLines + 1) },
                                      ]
                                    : themedStyle.inputMultiline
                                : undefined,
                        ]}
                        {...themedTextProps}
                        autoCapitalize={autoCapitalize}
                        autoCorrect={autoCorrect}
                        textAlign="left"
                        onChangeText={onChange}
                        keyboardType={getKeyboardType(type)}
                        autoComplete={getAutoComplete(type)}
                        textContentType={getTextContentType(type)}
                        {...textProps}
                        value={value}
                    />
                    {textProps.rightImage}
                </View>
                {suffix ? (
                    <View style={[themedStyle.suffix, { borderColor: value ? theme.main : theme.secondaryLight }]}>
                        <HeimeText style={themedStyle.suffixText}>{suffix}</HeimeText>
                    </View>
                ) : null}
            </View>
        </View>
    );
};

const getKeyboardType = (type: ControlledInputProps['type']): ComponentProps<typeof RNTextInput>['keyboardType'] => {
    switch (type) {
        case 'number':
            return 'number-pad';
        case 'email':
            return 'email-address';
        default:
            return undefined;
    }
};

const getAutoComplete = (type: ControlledInputProps['type']): ComponentProps<typeof RNTextInput>['autoComplete'] => {
    switch (type) {
        case 'nameGiven':
            return 'name-given';
        case 'familyName':
            return 'name-family';
        case 'email':
            return 'email';
        default:
            return undefined;
    }
};

const getTextContentType = (
    type: ControlledInputProps['type'],
): ComponentProps<typeof RNTextInput>['textContentType'] => {
    switch (type) {
        case 'nameGiven':
            return 'givenName';
        case 'familyName':
            return 'familyName';
        case 'email':
            return 'emailAddress';
        default:
            return undefined;
    }
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        container: {
            justifyContent: 'center',
            flexGrow: 0,
            flexShrink: 0,
        },
        contentContainer: {
            flexDirection: 'row',
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: 'center',
            borderRadius,
            flex: 1,
        },
        inputMultiline: {
            textAlignVertical: 'top',
            minHeight: isWeb() ? fontSize * 5 : undefined,
        },
        input: {
            flex: 1,
            textAlignVertical: 'center',
            paddingRight: padding,
            paddingLeft: padding,
            paddingTop: padding,
            paddingBottom: padding,
            fontFamily: _fonts.primaryFont,
            color: theme.black,
            fontSize,
        },
        row: { flexDirection: 'row', alignItems: 'flex-end' },
        suffix: {
            paddingLeft: padding,
            paddingRight: padding,
            paddingTop: padding,
            paddingBottom: padding,
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            backgroundColor: theme.lightBackground,
            borderRightWidth: 1.5,
            borderTopWidth: 1.5,
            borderBottomWidth: 1.5,
            borderLeftWidth: 0,
            alignItems: 'center',
            justifyContent: 'center',
        },
        suffixText: {
            color: theme.black,
            fontWeight: 'bold',
            fontSize: smallestFontSize,
        },
        inputWithSuffixContainer: { borderRightWidth: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 },
        inputContainer: { width: '100%', display: 'flex', flexDirection: 'row' },
    });

const platformStyles = Platform.select({
    default: { flexBasis: 'auto' as const },
    web: { flexBasis: 'min-content' as DimensionValue },
});

export default ControlledInput;
