import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TextInput, TouchableOpacity } from 'react-native';
import { smallestMargin } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import HeimeText from 'Components/HeimeText';
import Icon from 'Components/Icon/Icon';

interface EditLinkBarProps {
    onBlur: () => void;
    onEditLink: (newLink: string) => void;
    onLinkIconClick: () => void;
    initialLink: string | undefined;
}

export const EditLinkBar = ({ initialLink, onEditLink, onLinkIconClick, onBlur }: EditLinkBarProps) => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();
    const [link, setLink] = React.useState(initialLink || '');

    const handleEditLink = () => onEditLink(link);
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity
                onPress={onLinkIconClick}
                style={{
                    padding: 5,
                    backgroundColor: theme.lightGrey,
                    borderRadius: 100,
                }}
            >
                <Icon name="link" style={{ width: 24, height: 24 }} color="darkGray" />
            </TouchableOpacity>
            <TextInput
                value={link}
                onBlur={onBlur}
                onChangeText={setLink}
                placeholder={t('global:enter_url')}
                placeholderTextColor={theme.main}
                autoFocus
                autoCapitalize="none"
                style={{
                    backgroundColor: theme.mainBackground,
                    flex: 1,
                    padding: smallestMargin,
                    marginHorizontal: smallestMargin,
                }}
                onSubmitEditing={handleEditLink}
            />
            <TouchableOpacity style={{ padding: smallestMargin }} onPress={handleEditLink}>
                <HeimeText style={{}}>{t('global:insert_link')}</HeimeText>
            </TouchableOpacity>
        </View>
    );
};

export default EditLinkBar;
