import React, { ReactElement, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import { useOwnProfile } from '_api/useProfile';
import { addBreadcrumb, captureException } from '_utils/Sentry';
import { ProductBookingDetails } from './components';
import { useGetActivity } from '../../_api/useActivities';
import { useGetTransaction } from '../../_api/useTransactions';
import { SafeAreaView } from '../../_dependencies/safeArea';
import Images from '../../_images';
import { useAppNavigation } from '../../_navigator';
import { getRelatedDateString, openURL, Theme, useThemeStyle, WH, WW } from '../../_utils';
import { screenMargin, subtitleFontSize, titleFontSize } from '../../_utils/sizes';
import {
    CacheImage,
    FullWidthPictureCarousel,
    HeimeText,
    Loader,
    PrimaryButton,
    SecondaryButton,
    SelectorChip,
    SmallHeader,
} from '../../Components';
import { UnixTimeCode } from '../../types/Utility';

interface TransactionProps {
    route: {
        params: {
            transaction_id: string;
        };
    };
}

// User that is experiencing infinite loading
const userIdWithIssue = 11281;

const Transaction = ({ route }: TransactionProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { navigate, goBack, canGoBack } = useAppNavigation();
    const queryClient = useQueryClient();
    const { data: ownProfile } = useOwnProfile();
    const { data, isLoading, isError, error, isRefetching, refetch, isFetching } = useGetTransaction(
        route.params?.transaction_id,
    );

    const handleNavigateToVipps = () => {
        if (data?.redirect) {
            openURL(data.redirect, t);
        }
    };

    const handleNavigateToReservations = () => {
        if (data?.type === 'booking') {
            navigate('ReservationSelected', { id: data.product.id + '', initiallyUnlockLock: false });
        }
    };
    const handleNavigateToActivity = () => {
        if (data?.type === 'activity') {
            navigate('ActivitySelected', { activityId: data.product.activity_id + '' });
        }
    };

    const waiting = isLoading || data?.status === 'requested';
    const cancelled = data?.status === 'canceled' || data?.status === 'aborted';

    if (isError) {
        throw error;
    }

    useEffect(() => {
        if (ownProfile?.id === userIdWithIssue) {
            addBreadcrumb(
                'debugInfo',
                JSON.stringify({
                    isLoading,
                    data,
                    isError,
                    isRefetching,
                    waiting,
                    cancelled,
                    isFetching,
                }),
                'error',
            );
            captureException('Transaction opened with user and his problems:' + userIdWithIssue);
        }
    }, [cancelled, data, isError, isFetching, isLoading, isRefetching, ownProfile?.id, waiting]);

    useEffect(() => {
        if (data?.status === 'requested') {
            const interval = setInterval(() => {
                if (!isRefetching) {
                    if (ownProfile?.id === userIdWithIssue) {
                        captureException('Transaction is in requested state for user with id: ' + userIdWithIssue);
                    }
                    refetch();
                } else if (ownProfile?.id === userIdWithIssue) {
                    captureException('Transaction is not refetching for user with id: ' + userIdWithIssue);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [data?.status, isRefetching, refetch, ownProfile?.id]);

    useEffect(() => {
        if (data?.status === 'canceled' || data?.status === 'paid') {
            if (data.type === 'activity') {
                queryClient.invalidateQueries({
                    queryKey: ['activity'],
                });
                queryClient.invalidateQueries({
                    queryKey: ['signedupUsers'],
                });
            }
            if (data.type === 'booking') {
                queryClient.invalidateQueries({
                    queryKey: ['reservations'],
                });
                queryClient.invalidateQueries({
                    queryKey: ['activities'],
                });
            }
        }
    }, [data?.status, data?.type, queryClient]);

    return (
        <SafeAreaView
            edges={canGoBack() ? ['bottom', 'left', 'right'] : ['bottom', 'left', 'right', 'top']}
            style={themedStyle.full}
        >
            <SmallHeader
                onClose={() => (canGoBack() ? goBack() : navigate('StartScreen', undefined))}
                title={t('transaction:title')}
            />
            <HeimeText style={themedStyle.title}>
                {waiting ? t('transaction:waiting') : cancelled ? t('transaction:cancelled') : t('transaction:success')}
            </HeimeText>
            {waiting ? (
                <Loader />
            ) : cancelled ? (
                <ScrollView bounces={false} contentContainerStyle={themedStyle.scroller}>
                    <CacheImage source={Images.failWall} style={themedStyle.mainImage} resizeMode={'contain'} />
                    <HeimeText style={themedStyle.explainer}>
                        {data?.type === 'booking'
                            ? t('transaction:cancelledExplainerBooking')
                            : t('transaction:cancelledExplainerActivity')}
                    </HeimeText>
                    <SecondaryButton style={themedStyle.backButton} text={t('transaction:back')} onPress={goBack} />
                </ScrollView>
            ) : null}
            {data?.type === 'booking' ? (
                <ProductBookingDetails
                    productId={data?.product.product_id}
                    show={data.status === 'paid'}
                    productName={data?.product.name}
                    quantity={data?.product.quantity}
                    start={data.product.from}
                    end={data.product.to}
                    onNavigateToReservations={handleNavigateToReservations}
                />
            ) : data?.type === 'activity' ? (
                <ProductActivityDetails
                    activityId={data.product.activity_id}
                    show={data.status === 'paid'}
                    activityName={data?.product.name}
                    start={data.product.start}
                    onNavigateToActivity={handleNavigateToActivity}
                    quantity={data?.product.quantity}
                />
            ) : null}
            {data?.status === 'requested' ? (
                <>
                    <SecondaryButton
                        style={themedStyle.backButton}
                        text={t('transaction:toVipps')}
                        onPress={handleNavigateToVipps}
                    />
                    <PrimaryButton
                        style={themedStyle.backButton}
                        text={t('transaction:pay')}
                        onPress={handleNavigateToVipps}
                    />
                </>
            ) : null}
        </SafeAreaView>
    );
};

interface ProductActivitySignupDetailsProps {
    activityId: number;
    show: boolean;
    activityName: string;
    start: UnixTimeCode;
    quantity: number;
    onNavigateToActivity(): void;
}

const ProductActivityDetails = ({
    activityId,
    show,
    activityName,
    start,
    quantity,
    onNavigateToActivity,
}: ProductActivitySignupDetailsProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const { data } = useGetActivity(activityId);

    if (!show) {
        return <></>;
    }

    const startMoment = moment.unix(start);
    const [startString] = getRelatedDateString(startMoment.toDate());

    const nTickets = data?.approved_sum ? data.approved_sum : quantity;
    return (
        <>
            <ScrollView bounces={false}>
                <HeimeText style={themedStyle.infoTxt}>
                    {t('transaction:has_been_signedup')}
                    <Text style={themedStyle.emphasis}>{data?.title ?? activityName}</Text>
                </HeimeText>
                {data?.pictures?.length ? (
                    <FullWidthPictureCarousel disablePicturePress pictures={data?.pictures} />
                ) : null}
                <HeimeText style={themedStyle.infoTxt}>
                    {t('transaction:quantity_pre')}
                    <Text style={themedStyle.emphasis}>{nTickets}</Text>
                    {nTickets === 1 ? t('transaction:quantity_post_single') : t('transaction:quantity_post_multiple')}
                </HeimeText>
                <View style={themedStyle.chipContainer}>
                    <SelectorChip title={startString} variant="WhitePrimary" containerStyle={themedStyle.chip} />
                    <SelectorChip
                        title={
                            startMoment.isBefore(moment())
                                ? t('transaction:started')
                                : t('transaction:timeUntilStart', {
                                      formattedDuration: moment
                                          .duration(Math.abs(moment().diff(startMoment)))
                                          .humanize(),
                                  })
                        }
                        variant="PrimaryWhite"
                        containerStyle={themedStyle.chip}
                    />
                </View>
            </ScrollView>

            <SecondaryButton
                style={themedStyle.backButton}
                text={t('transaction:toActivity')}
                onPress={onNavigateToActivity}
            />
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        full: {
            height: '100%',
            backgroundColor: theme.mainBackground,
            width: '100%',
        },
        title: {
            fontSize: titleFontSize,
            fontWeight: 'bold',
            marginTop: WH * 0.05,
            textAlign: 'center',
        },
        scroller: {
            flex: 1,
            height: '100%',
        },
        explainer: {
            fontSize: subtitleFontSize,
            marginRight: screenMargin,
            marginLeft: screenMargin,
            textAlign: 'center',
        },
        mainImage: {
            height: WH * 0.345,
            width: '100%',
        },
        emphasis: {
            fontWeight: 'bold',
            color: theme.black,
        },
        infoTxt: {
            fontSize: subtitleFontSize,
            color: theme.secondaryText,
            textAlign: 'center',
            marginTop: WW * 0.06,
            marginBottom: WW * 0.06,
            lineHeight: WW * 0.06,
        },
        chipContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: WW * 0.02,
        },
        chip: {
            marginLeft: 4,
            marginRight: 4,
            marginTop: 4,
            marginBottom: 4,
        },
        backButton: {
            marginLeft: screenMargin,
            marginRight: screenMargin,
            marginTop: 'auto',
            marginBottom: screenMargin,
        },
    });
export default Transaction;
