import ActivitiesAll from './Activities/ActivitiesAll';
import ActivityNew from './Activities/ActivityNew';
import ActivitySelected from './Activities/ActivitySelected';
import ActivitySuggestions from './Activities/ActivitySuggestions/ActivitySuggestions';
import ActivitySuggestionSelected from './Activities/ActivitySuggestionSelected/ActivitySuggestionSelected';
import Board from './Board';
import ChatAll from './ChatAll';
import ChatInfo from './ChatInfo/ChatInfo';
import ChatNewMembers from './ChatNewMembers';
import ChatNewName from './ChatNewName';
import ChatSelected from './ChatSelected/ChatSelected';
import Contact from './Contact';
import CreatePin from './CreatePin';
import Documents from './Documents';
import FolderSelected from './FolderSelected';
import GroupNew from './GroupNew';
import GroupsAll from './GroupsAll';
import GroupSelected from './GroupSelected';
import Home from './Home';
import HomeEdit from './HomeEdit';
import ImagePreview from './ImagePreview/ImagePreview';
import ManageLicensePlates from './ManageLicensePlates';
import MemberBenefitsAll from './MemberBenefit/MemberBenefitsAll';
import MemberBenefitSelected from './MemberBenefit/MemberBenefitSelected';
import MoreMenu from './MoreMenu';
import MyHome from './MyHome';
import NeighborsAll from './NeighboursAll/NeighborsAll';
import Notifications from './Notifications';
import AllowPushAlertsStep from './Onboarding/AllowPushAlertsStep';
import JoinGroups from './Onboarding/JoinGroups';
import PrivacyPolicy from './Onboarding/PrivacyPolicy';
import ReservationFinal from './Payment/ReservationFinal';
import Transaction from './Payment/Transaction';
import ProductDetail from './ProductDetail';
import ProductReserve from './ProductReserve';
import Profile from './Profile';
import { ProfileEdit, ProfileConfirmInfo, ProfileDescribeInfo } from './ProfileEdit';
import ProfileAboutMe from './ProfileEdit/ProfileAboutMe';
import RateHeime from './RateHeime';
import ReportNew from './ReportNew';
import ReportsAll from './ReportsAll';
import ReportSelected from './ReportSelected';
import Procedure from './Reservations/Procedure';
import ReservationsAll from './Reservations/ReservationsAll';
import EditReservationUsers from './Reservations/ReservationSelected/EditReservationUsers';
import ReservationSelected from './Reservations/ReservationSelected/ReservationSelected';
import Category from './Services/Category';
import Services from './Services/Services';
import SettingsMenu from './SettingsMenu';
import HelpfulNeighbor from './Sharing/HelpfulNeighbor';
import Items from './Sharing/Items';
import NeighborSharing from './Sharing/NeighborSharing';
import NoticesAll from './Sharing/NoticesAll/NoticesAll';
import OwnSharingCategory from './Sharing/SharingAll/OwnSharingCategory';
import SharingCategorySelected from './Sharing/SharingAll/SharingCategorySelected';
import SharingNew from './Sharing/SharingNew';
import SharingSelected from './Sharing/SharingSelected/SharingSelected';
import SurveysAll from './Surveys/SurveysAll';
import SurveySelected from './Surveys/SurveySelected';
import TenantEdit from './TenantEdit/TenantEdit';
import TermsOfSale from './TermsOfSale';

export default {
    MoreMenu,
    TermsOfSale,
    MemberBenefitsAll,
    MemberBenefitSelected,
    Home,
    NeighborSharing,
    Profile,
    ActivitiesAll,
    MyHome,
    Contact,
    ActivitySelected,
    ChatSelected,
    ChatAll,
    SharingNew,
    ReservationFinal,
    ActivityNew,
    NeighborsAll,
    ReservationsAll,
    Services,
    SharingCategorySelected,
    OwnSharingCategory,
    ProfileEdit,
    Board,
    Documents,
    Notifications,
    JoinGroups,
    GroupSelected,
    GroupsAll,
    GroupNew,
    ProductDetail,
    Category,
    ProductReserve,
    ReservationSelected,
    PrivacyPolicy,
    AllowPushAlertsStep,
    HelpfulNeighbor,
    CreatePin,
    SharingSelected,
    ChatInfo,
    ImagePreview,
    ChatNewMembers,
    ChatNewName,
    SettingsMenu,
    NoticesAll,
    Items,
    ReportsAll,
    ReportNew,
    ReportSelected,
    FolderSelected,
    HomeEdit,
    Transaction,
    SurveysAll,
    SurveySelected,
    TenantEdit,
    ProfileConfirmInfo,
    ProfileDescribeInfo,
    ManageLicensePlates,
    RateHeime,
    ProfileAboutMe,
    Procedure,
    ActivitySuggestions,
    ActivitySuggestionSelected,
    EditReservationUsers,
};
