import React, { ReactElement, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { dismissRecommendedAppVersion, resetRecommendedAppVersion } from '_redux/modals';
import useShouldUpdateApp from '_utils/hooks/useShouldUpdateApp';
import OpenStore from '_utils/openStore';
import { screenMargin, smallestMargin } from '_utils/sizes';
import DividedImageModal from './components/DividedImageModal';
import { isiOS, isWeb, useGlobalState, useAppDispatch, WW, WH } from '../../_utils';
import { HeimeText, MainLogo, PrimaryButton, SecondaryButton } from '../../Components';

const UpdateAppAlert = (): ReactElement | null => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const recommendedAppVersionDismissedAt = useGlobalState(
        (state) => state.addProfileModal.recommendedAppVersionDismissedAt,
    );

    const showModal = useShouldUpdateApp();

    useEffect(() => {
        if (!showModal) {
            dispatch(resetRecommendedAppVersion());
        }
    }, [dispatch, showModal]);

    if (
        !showModal ||
        isWeb() ||
        (showModal === 'recommended' &&
            recommendedAppVersionDismissedAt &&
            moment.unix(recommendedAppVersionDismissedAt).isAfter(moment().subtract({ day: 1 })))
    ) {
        return null;
    }

    const handleDismissClick = () => {
        if (showModal === 'recommended') {
            dispatch(dismissRecommendedAppVersion(moment().unix()));
        }
    };
    const handleGoToStore = () => OpenStore(t);

    const isIos = isiOS();

    return (
        <DividedImageModal
            onDismiss={handleDismissClick}
            hideCloseButton={showModal === 'force'}
            top={
                <View style={{ alignItems: 'center', width: '100%' }}>
                    <MainLogo imageWidth={WW * 0.6} imageHeight={WH * 0.15} />
                </View>
            }
            bottom={
                <View style={{ width: WW * 0.8, gap: smallestMargin }}>
                    <HeimeText variant="title">{t('updateApp:header')}</HeimeText>
                    <HeimeText variant="subtitle" style={{ textAlign: 'center' }}>
                        {t(
                            showModal === 'force' ? 'updateApp:description_force' : 'updateApp:description_recommended',
                            {
                                store: t(`updateApp:${isIos ? 'ios' : 'android'}`),
                            },
                        )}
                    </HeimeText>
                    {showModal === 'recommended' && (
                        <HeimeText variant="subtitle" style={{ textAlign: 'center' }}>
                            {t('updateApp:description_force_soon')}
                        </HeimeText>
                    )}

                    <PrimaryButton
                        onPress={handleGoToStore}
                        style={{ marginTop: screenMargin }}
                        text={t('updateApp:goToButton', { store: t(`updateApp:${isIos ? 'ios' : 'android'}`) })}
                    />
                    {showModal === 'recommended' ? (
                        <SecondaryButton onPress={handleDismissClick} text={t('updateApp:later')} />
                    ) : null}
                </View>
            }
        />
    );
};

export default UpdateAppAlert;
