import { ReactElement } from 'react';
import { useFindReservation } from '_api/useReservations';
import { isAppError } from '_utils';
import { NotFoundErrorScreen, Loader } from 'Components';
import { OwnReservation } from 'types/Reservation';

const FindReservation = ({
    reservationId,
    children,
}: {
    reservationId: number;
    children: (props: { reservation: OwnReservation; refetch: () => void; isRefetching: boolean }) => ReactElement;
}) => {
    const {
        reservation: foundReservation,
        isLoading,
        isFetchingNextPage,
        isError,
        error,
        refetch,
        isRefetching,
    } = useFindReservation(reservationId);

    if (foundReservation) {
        return children({ reservation: foundReservation, refetch, isRefetching });
    }

    if (isLoading || isFetchingNextPage || isRefetching) {
        return <Loader />;
    }

    if (isError) {
        if (isAppError(error) && error.response?.status === 404) {
            return <NotFoundErrorScreen type="Reservation" />;
        } else {
            throw error;
        }
    }

    return <NotFoundErrorScreen type="Reservation" />;
};

export default FindReservation;
