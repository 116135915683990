import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBridgeState, useEditorContent } from '@10play/tentap-editor';
import { useFocusEffect } from '@react-navigation/native';
import { useFormContext } from 'react-hook-form';
import { PixelRatio, View } from 'react-native';
import { isAndroid, isWeb } from '_utils';
import { track } from '_utils/Amplitude';
import { useKeyboardContext } from '_utils/KeyboardContext';
import { addBreadcrumb, captureException } from '_utils/Sentry';
import { useThemeContext } from '_utils/themeContext';
import InputLabel from 'Components/InputLabel';
import BaseRichText from 'Components/RichText/BaseRichRext';
import { useEditor } from 'Components/RichText/useEditor';
import LinkToolbar from './components/LinkToolbar';
import RichTextToolbar from './components/RichTextToolbar';
import { borderRadius, borderWidth, fontSize, padding } from '../formStyle';

const FormRichText = ({ name, label, placeholder }: { name: string; label: string; placeholder: string }) => {
    const formContext = useFormContext();
    const { theme } = useThemeContext();

    const editor = useEditor({
        initialContent: () => formContext.getValues(name),
        placeholder,
        containerStyle: {
            borderColor: theme.secondaryLight,
            borderWidth: borderWidth,
            borderTopWidth: borderWidth,
            borderRadius: borderRadius,
        },
    });

    const bridgeState = useBridgeState(editor);
    const { disableToolbar, enableToolbarContent } = useKeyboardContext();
    const [isEditingLink, setIsEditingLink] = useState<{ from: number; to: number } | null>(null);

    const editorState = editor.getEditorState();
    const isBoldActive = editorState.isBoldActive;
    const isItalicActive = editorState.isItalicActive;
    const isUnderlineActive = editorState.isUnderlineActive;
    const isLinkActive = editorState.isLinkActive;
    const canSetLink = editorState.canSetLink;
    const isStrikeThroughActive = editorState.isStrikeActive;
    const isNumberedListActive = editorState.isOrderedListActive;
    const isBulletListActive = editorState.isBulletListActive;

    const trackEvent = useCallback((type: string, func: () => void) => {
        track('Richtext toggled', { type });
        func();
    }, []);

    const toolbarContent = useMemo(
        () =>
            isEditingLink ? (
                <LinkToolbar
                    onBlur={() =>
                        setTimeout(
                            () => {
                                setIsEditingLink(null);
                            },
                            isWeb() ? 100 : 0,
                        )
                    }
                    onEditLink={(link: string) => {
                        if (isAndroid()) {
                            editor.setSelection(isEditingLink.from, isEditingLink.to);
                        }
                        editor.setLink(link);
                        editor.focus();
                        editor.setSelection(isEditingLink.to, isEditingLink.to);
                    }}
                    onLinkIconClick={() => setIsEditingLink(null)}
                    initialLink={editorState.activeLink}
                />
            ) : (
                <RichTextToolbar
                    buttons={[
                        {
                            active: isBoldActive,
                            icon: 'bold',
                            onPress: () => trackEvent('bold', editor.toggleBold),
                        },
                        {
                            active: isItalicActive,
                            icon: 'italic',
                            onPress: () => trackEvent('italic', editor.toggleItalic),
                        },
                        {
                            active: isUnderlineActive,
                            icon: 'underline',
                            onPress: () => trackEvent('underline', editor.toggleUnderline),
                        },
                        {
                            active: isEditingLink !== null,
                            icon: 'link',
                            disabled: !canSetLink && !isLinkActive,
                            onPress: () => setIsEditingLink(editorState.selection),
                        },
                        {
                            active: isStrikeThroughActive,
                            icon: 'strikeThrough',
                            onPress: () => trackEvent('strikeThrough', editor.toggleStrike),
                        },
                        {
                            active: isNumberedListActive,
                            icon: 'textListOrdered',
                            onPress: () => trackEvent('numberedList', editor.toggleOrderedList),
                        },
                        {
                            active: isBulletListActive,
                            icon: 'textListUnordered',
                            onPress: () => trackEvent('bulletList', editor.toggleBulletList),
                        },
                    ]}
                />
            ),
        // We only want the state to change when the toolbar is active
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isBoldActive,
            isItalicActive,
            isUnderlineActive,
            isLinkActive,
            canSetLink,
            isStrikeThroughActive,
            isNumberedListActive,
            isBulletListActive,
            isEditingLink,
        ],
    );

    useFocusEffect(
        useCallback(() => {
            if (bridgeState.isFocused) {
                enableToolbarContent(toolbarContent, true);
                return disableToolbar;
            } else if (isEditingLink) {
                enableToolbarContent(toolbarContent, false);
                return disableToolbar;
            } else {
                disableToolbar();
            }
        }, [bridgeState.isFocused, disableToolbar, enableToolbarContent, isEditingLink, toolbarContent]),
    );

    useFocusEffect(
        useCallback(() => {
            addBreadcrumb('richtext', 'focus');
            return () => {
                addBreadcrumb('richtext', 'blur');
            };
        }, []),
    );

    const content = useEditorContent(editor, { type: 'json' });

    addBreadcrumb('richtext', 'render');

    useEffect(() => {
        const newVal = JSON.stringify(content);
        addBreadcrumb('richtext', newVal);
        addBreadcrumb('richtext', bridgeState.isReady ? 'ready' : 'not ready');
        if (bridgeState.isReady) {
            const oldVal = JSON.stringify(formContext.getValues(name));
            if (newVal && newVal !== oldVal) {
                if (isReportableOccurence(content)) {
                    captureException(new Error('RICHTEXT reportable occurence: ' + newVal));
                }
                formContext.setValue(name, content, { shouldDirty: true });
            }
        }
    }, [bridgeState.isReady, content, formContext, name]);

    const actualFontSize = PixelRatio.getFontScale() * fontSize;

    const wrapperStyle = {
        flex: 1,
        minHeight: actualFontSize * 8,
    };

    if (!isWeb()) {
        return (
            <View style={wrapperStyle}>
                <InputLabel>{label}</InputLabel>
                <BaseRichText fontSize={actualFontSize} editor={editor} containerStyle={ContainerStyle} />
            </View>
        );
    } else {
        return (
            <View>
                <InputLabel>{label}</InputLabel>
                <View
                    style={{
                        borderWidth: borderWidth,
                        borderColor: theme.secondaryLight,
                        borderRadius: borderRadius,
                    }}
                >
                    {toolbarContent}
                    <View style={wrapperStyle}>
                        <BaseRichText fontSize={actualFontSize} editor={editor} containerStyle={ContainerStyle} />
                    </View>
                </View>
            </View>
        );
    }
};

const isReportableOccurence = (obj: unknown) => {
    if (Array.isArray(obj)) {
        return true;
    }
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length === 0;
};

const ContainerStyle = `

padding-top: ${padding}px;
padding-bottom: ${padding}px;
padding-left: ${padding}px;
padding-right: ${padding}px;

`;

export default FormRichText;
