import { useIsFocused } from '@react-navigation/native';
import {
    InfiniteData,
    QueryClient,
    QueryFilters,
    QueryKey,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useQueryClient,
} from '@tanstack/react-query';
import { AxiosPromise } from 'axios';
import { z } from 'zod';
import safeParse from '_utils/safeParse';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop/hooks';
import { createLaravelPagingResponseSchema, FileToBeUploaded, LaravelPagingResponse } from '../types/Utility';

export const useLaravelInfinteQuery = <ItemType, ErrorType = string | Error>(
    queryKey: QueryKey,
    queryFunc: (params: {
        pageParam: number | unknown;
        selectedCoopId: number;
        getAuthHeader(): Promise<string>;
    }) => AxiosPromise<LaravelPagingResponse<ItemType>>,
    queryOptions: Omit<
        UseInfiniteQueryOptions<LaravelPagingResponse<ItemType>, ErrorType, InfiniteData<ItemType>>,
        'queryKey' | 'queryFn' | 'getNextPageParam' | 'initialPageParam'
    > = {},
    zodSchema?: z.ZodSchema<ItemType, z.ZodTypeDef, unknown>,
): UseInfiniteQueryResult<InfiniteData<ItemType>, ErrorType> => {
    const isFocused = useIsFocused();
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();

    const values = useInfiniteQuery<LaravelPagingResponse<ItemType>, ErrorType, InfiniteData<ItemType>>({
        queryKey,
        queryFn: async (params) => {
            const result = await queryFunc({ ...params, selectedCoopId, getAuthHeader });

            if (!result.data.data) {
                throw new Error('Result returned with success == false');
            }
            if (zodSchema) {
                return safeParse(
                    result.data,
                    createLaravelPagingResponseSchema(zodSchema),
                ) as LaravelPagingResponse<ItemType>;
            }
            return result.data;
        },
        getNextPageParam: getNextPageParamFromLaravelPaging,
        initialPageParam: 1,
        select: selectDataPerPage,
        enabled: isFocused,
        ...queryOptions,
    });

    return values;
};

const getNextPageParamFromLaravelPaging = (lastPage: LaravelPagingResponse<unknown>): number | undefined => {
    if (lastPage.meta && lastPage.meta.last_page > lastPage.meta.current_page) {
        return lastPage.meta.current_page + 1;
    }
};

const selectDataPerPage = <ItemType>(responseData: InfiniteData<LaravelPagingResponse<ItemType>>) => ({
    ...responseData,
    pages: responseData.pages.map((page) => page.data),
});

export const getFileObjectFunc = (objectName: string): ((arg: FileToBeUploaded) => { data: string; name: string }) =>
    (({ uri, index, name }: FileToBeUploaded) => ({ data: uri, name: name ? name : objectName + index })) ?? [];

export const getPlaceholderSearchData =
    <ReturnType extends { id: number }>(
        queryClient: QueryClient,
        queryFilters: QueryFilters,
        itemFilter: (item: ReturnType) => boolean,
        sort?: (a: ReturnType, b: ReturnType) => number,
    ) =>
    (): InfiniteData<LaravelPagingResponse<Array<ReturnType>>> | undefined => {
        const relatedQuerydata =
            queryClient.getQueriesData<InfiniteData<LaravelPagingResponse<Array<ReturnType>>>>(queryFilters);
        const foundItemIds = new Set();
        const foundItems: ReturnType[] = [];
        relatedQuerydata?.forEach(([, result]) => {
            result?.pages?.forEach(({ data }) =>
                data?.forEach((item) => {
                    if (foundItemIds.has(item.id)) {
                        return;
                    }

                    if (itemFilter(item)) {
                        foundItemIds.add(item.id);
                        foundItems.push(item);
                    }
                }),
            );
        });
        if (foundItems.length === 0) {
            return undefined;
        }

        const sorted = sort ? foundItems.sort(sort) : foundItems;
        return { pages: [{ data: sorted, meta: {} } as LaravelPagingResponse<ReturnType[]>], pageParams: [] };
    };

export const useOptimisticUpdate = <MutationData, DataType, ErrorType>(
    queryKeyFunc: QueryKey | ((mutationData: MutationData) => QueryKey),
    updater: (props: MutationData) => (oldData: DataType) => DataType,
) => {
    const queryClient = useQueryClient();
    return {
        onMutate: async (props: MutationData) => {
            const queryKey = typeof queryKeyFunc === 'function' ? queryKeyFunc(props) : queryKeyFunc;
            await queryClient.cancelQueries({ queryKey });

            const previousData = queryClient.getQueryData<DataType>(queryKey);

            queryClient.setQueryData<DataType>(queryKey, (data) => {
                if (data) {
                    return updater(props)(data);
                }
            });

            return { previousData };
        },
        onError: (
            _err: ErrorType,
            _props: MutationData,
            context: { previousData: DataType | undefined } | undefined,
        ) => {
            if (context?.previousData) {
                const queryKey = typeof queryKeyFunc === 'function' ? queryKeyFunc(_props) : queryKeyFunc;
                queryClient.setQueryData<DataType>(queryKey, context.previousData);
            }
        },
    };
};
