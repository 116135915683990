import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyle, StyleProp, View } from 'react-native';
import _fonts from '_fonts';
import { isWeb } from '_utils';
import { WH } from '_utils/dimensions';
import { smallestMargin, subtitleFontSize } from '_utils/sizes';
import { useThemeContext } from '_utils/themeContext';
import { openURL } from '_utils/url';
import { HeimeText } from 'Components';
import { RichText } from 'types/Base';

const RichTextView = ({ content, style }: { content: RichText[]; style?: StyleProp<TextStyle> }) => {
    const { theme } = useThemeContext();
    const { t } = useTranslation();

    const textProps = {
        variant: 'subtitle',
        style: [{ color: theme.black }, style] as StyleProp<TextStyle>,
        linkify: true,
        selectable: true,
        maxFontSizeMultiplier: 2,
        flexGrow: 0,
        flexShrink: 0,
    } as const;

    return content.map((item, index) => {
        switch (item.type) {
            case 'doc':
            case 'listItem':
                return item.content ? <RichTextView key={index} content={item.content} /> : null;
            case 'paragraph':
                return (
                    <HeimeText
                        key={index}
                        {...textProps}
                        style={[textProps.style, isWeb() ? { minHeight: subtitleFontSize } : undefined]}
                    >
                        {item.content ? <RichTextView key={index} content={item.content} /> : null}
                    </HeimeText>
                );
            case 'orderedList':
                return (
                    <ListItem
                        key={index}
                        content={item.content ?? []}
                        marker={(innerIndex: number) => (
                            <HeimeText {...textProps}>{item.attrs.start + innerIndex}.</HeimeText>
                        )}
                    />
                );
            case 'bulletList':
                return (
                    <ListItem
                        key={index}
                        content={item.content ?? []}
                        marker={<HeimeText {...textProps}>•</HeimeText>}
                    />
                );
            case 'hardBreak':
                return '\n';
            case 'text':
                const bold = item.marks?.some((mark) => mark.type === 'bold');
                const italic = item.marks?.some((mark) => mark.type === 'italic');
                const underline = item.marks?.some((mark) => mark.type === 'underline');
                const strike = item.marks?.some((mark) => mark.type === 'strike');
                const linkInfo = item.marks?.find((mark) => mark.type === 'link');

                return (
                    <HeimeText
                        key={index}
                        {...textProps}
                        style={[
                            textProps.style,
                            {
                                fontWeight: bold ? 'bold' : undefined,
                                fontStyle: italic ? 'italic' : undefined,
                                fontFamily: italic ? _fonts.primaryFontItalic : undefined,
                                textDecorationLine:
                                    underline && strike
                                        ? 'underline line-through'
                                        : underline
                                          ? 'underline'
                                          : strike
                                            ? 'line-through'
                                            : undefined,
                                color: linkInfo ? theme.hyperText : undefined,
                                justifyContent: 'flex-end',
                            },
                        ]}
                        onPress={linkInfo ? () => openURL(linkInfo.attrs.href, t, true) : undefined}
                    >
                        {item.text}
                    </HeimeText>
                );
            default:
                const neverItem = item as unknown;
                if (neverItem && typeof neverItem === 'object') {
                    if ('content' in neverItem) {
                        return <RichTextView key={index} content={neverItem.content as RichText[]} />;
                    } else if ('text' in neverItem) {
                        return (
                            <HeimeText key={index} {...textProps}>
                                {neverItem.text as string}
                            </HeimeText>
                        );
                    }
                }
                return null;
        }
    });
};

const ListItem = ({ content, marker }: { content: RichText[]; marker: ReactNode | ((index: number) => ReactNode) }) => {
    return (
        <View
            style={{
                marginLeft: WH * 0.02,
                justifyContent: 'flex-end',
                flex: 1,
            }}
        >
            {content.map((listItem, innerIndex) => (
                <View
                    key={innerIndex}
                    style={{
                        flexDirection: 'row',
                        gap: smallestMargin,
                        flex: 1,
                    }}
                >
                    {typeof marker === 'function' ? marker(innerIndex) : marker}
                    <View style={{ flex: 1 }}>
                        <RichTextView content={[listItem]} />
                    </View>
                </View>
            ))}
        </View>
    );
};

export default RichTextView;
