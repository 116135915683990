import { isiOS } from './isPlatform';
import { openURL } from './url';

const OpenStore = (t: Parameters<typeof openURL>[1]) => {
    if (isiOS()) {
        openURL('itms-apps://apps.apple.com/id/app/halojasa/id1545319598?l=no', t);
    } else {
        openURL('https://play.google.com/store/apps/details?id=com.heime.app', t);
    }
};

export default OpenStore;
