import React, { useEffect, ReactElement } from 'react';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { ErrorBoundary } from 'react-error-boundary';
import { StyleSheet, StatusBar, KeyboardAvoidingView } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Navigation from '_navigator/navigation';
import NavigationContainer from '_navigator/NavigationContainer';
import KeyboardProvider from '_utils/KeyboardContext';
import MenuProvider from 'Components/MenuProvider';
import { usePrefetchCoop } from './_api/useCoops';
import { usePrefetchProfile } from './_api/useProfile';
import { isiOS, useThemeStyle, Theme } from './_utils';
import { ThemeProvider } from './_utils/themeContext';
import { AppErrorScreen } from './Components';
import { AddProfileModal, UpdateAppAlert } from './Screens/GlobalModals';
import { SelectedCoopGate } from './SelectedCoop';
import { TimeSyncProvider } from './TimeSyncContext';
import useListenForNotifications from './useListenForNotifications';
import useRegisterForNotifications from './useRegisterForNotifications';

const AppContainer = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const prefetchProfile = usePrefetchProfile();
    const prefetchCoop = usePrefetchCoop();
    useEffect(() => {
        prefetchProfile();
        prefetchCoop();
    }, [prefetchCoop, prefetchProfile]);

    useRegisterForNotifications();
    useListenForNotifications();

    return (
        <ErrorBoundary FallbackComponent={AppErrorScreen}>
            <KeyboardProvider>
                <GestureHandlerRootView style={themedStyle.fullSize}>
                    <KeyboardAvoidingView style={themedStyle.fullSize} behavior={isiOS() ? 'padding' : undefined}>
                        {
                            isiOS() ? (
                                <StatusBar barStyle="dark-content" />
                            ) : null /* Fix for with twitching content on ios */
                        }
                        <UpdateAppAlert />
                        <NavigationContainer>
                            <TimeSyncProvider>
                                <SelectedCoopGate>
                                    <ThemeProvider>
                                        <BottomSheetModalProvider>
                                            <MenuProvider>
                                                <AddProfileModal />
                                                <Navigation />
                                            </MenuProvider>
                                        </BottomSheetModalProvider>
                                    </ThemeProvider>
                                </SelectedCoopGate>
                            </TimeSyncProvider>
                        </NavigationContainer>
                    </KeyboardAvoidingView>
                </GestureHandlerRootView>
            </KeyboardProvider>
        </ErrorBoundary>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        fullSize: {
            flex: 1,
        },
        backDrop: { backgroundColor: theme.black, opacity: 0.3 },
    });

export default AppContainer;
