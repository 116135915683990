import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { screenMargin } from '_utils/sizes';
import { ExternalUserProfile } from 'types/User';
import { ArrayElement } from 'types/Utility';
import _fonts from '../../../_fonts';
import { useThemeStyle } from '../../../_utils';
import { Theme } from '../../../_utils/themeContext';
import { HeaderWithNav, Icon, QueryView, EmptyMessage } from '../../../Components';

const ApartmentList = ({
    apartments,
    onPress,
    onBackPress,
    refetch,
    isLoading,
}: {
    onPress: (apartmentId: number) => void;
    apartments: Exclude<ExternalUserProfile['cooperative_apartments'], undefined>;
    onBackPress: () => void;
    refetch: () => void;
    isLoading: boolean;
}): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();

    const renderItem = ({ item }: { item: ArrayElement<typeof apartments> }) => {
        return <ApartmentItem onPress={onPress} apt={item} />;
    };

    return (
        <>
            <HeaderWithNav
                onPress={onBackPress}
                title={t('myHome:title').toUpperCase()}
                style={themedStyle.nav}
                safeArea
            />
            <QueryView
                data={apartments}
                style={themedStyle.main}
                onRefresh={refetch}
                isRefreshing={isLoading}
                renderItem={renderItem}
                emptyList={<EmptyMessage containerStyle={{ marginTop: screenMargin }} message={t('myHome:noResult')} />}
            />
        </>
    );
};

interface ApartmentItemProps {
    apt: ArrayElement<Exclude<ExternalUserProfile['cooperative_apartments'], undefined>>;
    onPress: (aptId: number) => void;
}

const ApartmentItem = ({ apt, onPress }: ApartmentItemProps): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    return (
        <TouchableOpacity
            key={apt.name}
            style={themedStyle.button}
            onPress={() => {
                onPress(apt.id);
            }}
        >
            <View style={themedStyle.itemContainer}>
                <Text style={themedStyle.text}>{apt.name}</Text>

                <Icon name="chevron" color="main" />
            </View>
        </TouchableOpacity>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        nav: {
            backgroundColor: theme.shadow,
        },
        main: {
            backgroundColor: theme.mainBackground,
        },
        button: { paddingLeft: 16, paddingRight: 16 },
        itemContainer: {
            display: 'flex',
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: theme.mediumBackground,
            paddingTop: 24,
            paddingBottom: 24,
            alignItems: 'center',
        },
        text: {
            fontWeight: 'bold',
            fontFamily: _fonts.primaryFont,
            color: theme.main,
            fontSize: 16,
            flex: 1,
            marginLeft: 8,
        },
    });

export default ApartmentList;
