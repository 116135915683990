import React, { ComponentProps, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { useFocusEffect } from '@react-navigation/native';
import { t as tGlobal } from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { ProductReservation } from '_api/useReservations';
import { getRelatedDateString } from '_utils';
import { screenMargin, titleFontSize } from '_utils/sizes';
import { CloseButton, HeimeText } from 'Components';
import BottomSheet from 'Components/Modal/Bottomsheet';
import SecondaryButton from 'Components/SecondaryButton';
import UserList from './UserList';

const buildDescription = (
    { quantity, status, reason, start_at, end_at }: ProductReservation,
    tFunc: typeof tGlobal,
) => {
    const [start, end] = getRelatedDateString(moment.unix(start_at).toDate(), moment.unix(end_at).toDate());
    const timeString = `${start} - ${end}`;
    const quantityString = quantity > 1 ? `${tFunc('serviceExpanded:quantity', { quantity })}` : '';
    const statusString =
        status === 'confirm'
            ? ''
            : status === 'in_cart'
              ? tFunc('serviceExpanded:not_confirmed')
              : tFunc('serviceExpanded:change_not_confirmed');
    const reasonString = reason ? reason : '';
    const endString = `${reasonString ? `${reasonString}` : ''} | ${timeString}`;
    if (quantityString && statusString) {
        return `${quantityString} | ${statusString}\n${endString}`;
    } else if (quantityString || statusString) {
        return `${quantityString || statusString}\n${endString}`;
    }
    return `${reasonString ? `${reasonString}\n` : ''}${timeString}`;
};

const BookedModal = ({ onDismiss, bookings }: { onDismiss(): void; bookings: ProductReservation[] }): ReactElement => {
    const { t } = useTranslation();

    const bottomSheetModalRef = useRef<BottomSheetModal>(null);
    const userList = useMemo(
        () =>
            bookings.reduce(
                (acc, booking) => [
                    ...acc,
                    ...booking.users.map((user) => ({
                        id: user.id,
                        title: user.name,
                        desc: buildDescription(booking, t),
                    })),
                ],
                [] as ComponentProps<typeof UserList>['data'],
            ),
        [bookings, t],
    );

    useFocusEffect(
        useCallback(() => {
            return () => {
                bottomSheetModalRef.current?.dismiss();
                onDismiss();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []),
    );

    useEffect(() => {
        bottomSheetModalRef.current?.present();
    }, []);

    const handleSheetChanges = useCallback(
        (index: number) => {
            if (index === -1) {
                onDismiss();
            }
        },
        [onDismiss],
    );

    const handleClose = () => {
        bottomSheetModalRef.current?.dismiss();
    };

    return (
        <BottomSheet
            ref={bottomSheetModalRef}
            enableDynamicSizing
            onChange={handleSheetChanges}
            top={
                <View style={styles.titleContainer}>
                    <HeimeText style={styles.title}>{t('serviceExpanded:who_booked')}</HeimeText>
                    <CloseButton style={{ transform: [{ translateX: screenMargin }] }} onPress={handleClose} />
                </View>
            }
            content={<UserList data={userList} />}
            bottom={<SecondaryButton text={t('serviceExpanded:close')} onPress={handleClose} status={null} />}
        />
    );
};

const styles = StyleSheet.create({
    title: {
        textAlign: 'left',
        fontSize: titleFontSize,
        fontWeight: 'bold',
    },
    titleContainer: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' },
});

export default BookedModal;
