import {
    QueryClient,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { useGetAccessTokenHeader } from '_utils/Axios';
import safeParse from '_utils/safeParse';
import { ProcedureInstanceSchema, ProcedureSchema } from 'types/Procedures';
import { useInvalidateNotifications } from './useNotifications';
import { invalidateReservationQueries } from './useReservations';
import { useSelectedCoop } from '../SelectedCoop';

const invalidateProcedureQueries = (queryClient: QueryClient): void => {
    queryClient.invalidateQueries({
        queryKey: ['procedure'],
    });
};

const ProcedureResponseSchema = z.object({
    procedure: ProcedureSchema,
    procedure_instance: ProcedureInstanceSchema.nullable(),
});

const useProcedure = (
    bookingId: number,
    procedureId: string,
): UseQueryResult<z.infer<typeof ProcedureResponseSchema>, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();

    return useQuery({
        queryKey: ['procedure', selectedCoopId, bookingId, procedureId],

        queryFn: async () => {
            const authHeader = await getAuthHeader();
            const result = await axios.get(
                `cooperatives/${selectedCoopId}/booking/${bookingId}/procedures/${procedureId}`,
                {
                    headers: { authorization: authHeader },
                },
            );

            if (result.data) {
                return safeParse(result.data, ProcedureResponseSchema);
            }
            throw new Error('Result returned with no data');
        },

        gcTime: Infinity,
        staleTime: 1000 * 60 * 5,
    });
};

interface AnswerActionBody {
    value: string | boolean | null;
    options: Array<{
        procedure_action_options_id?: string;
        file_id?: string;
    }>;
}

const useAnswerAction = (
    bookingId: number,
    procedureId: string,
    actionId: string,
): UseMutationResult<{ success: boolean }, string | Error, AnswerActionBody> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (answer: AnswerActionBody) => {
            const result = await axios.put<{ success: boolean }>(
                `cooperatives/${selectedCoopId}/booking/${bookingId}/procedures/${procedureId}/action/${actionId}`,
                answer,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Procedure action answer return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            invalidateProcedureQueries(queryClient);
        },
    });
};

const useMarkProcedureCompleted = (
    bookingId: number,
    procedureId: string,
): UseMutationResult<{ success: number }, string | Error> => {
    const selectedCoopId = useSelectedCoop();
    const getAuthHeader = useGetAccessTokenHeader();
    const queryClient = useQueryClient();
    const invalidateNotification = useInvalidateNotifications();

    return useMutation({
        mutationFn: async () => {
            const result = await axios.patch<{ success: number }>(
                `cooperatives/${selectedCoopId}/booking/${bookingId}/procedures/${procedureId}`,
                null,
                {
                    headers: { authorization: await getAuthHeader() },
                },
            );

            if (!result.data.success) {
                throw new Error('Procedure completion return unsuccessful result');
            }
            return result.data;
        },
        onSuccess: () => {
            invalidateProcedureQueries(queryClient);
            invalidateReservationQueries(queryClient);
            invalidateNotification();
        },
    });
};

export { useProcedure, useAnswerAction, useMarkProcedureCompleted };
