import { z } from 'zod';
import { ApiImageSchema } from './Base';
import { RoutePropBoolean, RoutePropNumber } from './Utility';

const BaseNotificationSchema = z.object({
    id: RoutePropNumber,
    created_at: RoutePropNumber,
    seen: RoutePropBoolean,
});

const NewChatMessageSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('NewChatMessage'),
        chat_room_id: z.number(),
        group_name: z.string(),
        sender: z.string(),
        text: z.string(),
        image: ApiImageSchema,
    }),
);
const BaseSupportSchema = BaseNotificationSchema.merge(
    z.object({
        support_id: z.number(),
        title: z.string(),
        creator: z.number(),
    }),
);

const SupportNotificationSchema = BaseSupportSchema.merge(
    z.object({
        type: z.literal('SupportCreated'),
    }),
);

const SupportStatusSchema = BaseSupportSchema.merge(
    z.object({
        type: z.literal('SupportStatus'),
        status: z.number(),
    }),
);

const SupportNoteSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('SupportNoteCreated'),
        support_id: z.number(),
        title: z.string(),
        creator: z.number(),
        content: z.string(),
    }),
);

const MessageCreatedSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('MessageCreated'),
        notice_id: z.number(),
    }),
);

const ActivityNotificationSchema = BaseNotificationSchema.merge(
    z.object({
        activity_id: z.number(),
        title: z.string(),
        start_at: z.number(),
        creator: z.string(),
        image: ApiImageSchema.optional(),
    }),
);

const ActivityInviteSchema = ActivityNotificationSchema.merge(
    z.object({
        type: z.union([z.literal('ActivityInvite'), z.literal('ActivityInvitedUsers')]),
    }),
);

const ActivityCustomNotifySchema = ActivityNotificationSchema.merge(
    z.object({
        type: z.literal('ActivityCustomNotify'),
        title: z.string(),
        content: z.string(),
    }),
);

const TFBeforeActivtity = ActivityNotificationSchema.merge(
    z.object({
        type: z.union([z.literal('24HoursBeforeActivity'), z.literal('24HoursBeforeRegistrationDeadline')]),
    }),
);

const CancelledActivitySchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('CancelledActivity'),
        title: z.string(),
    }),
);

const UserGroupCreatedSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('UserGroupCreated'),
        user_group_id: z.number(),
        title: z.string(),
        content: z.string(),
        image: ApiImageSchema.optional(),
    }),
);

const LockLowBatterySchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('LockLowBattery'),
        lock_id: z.number(),
        lock_name: z.string(),
        lock_type: z.union([
            z.literal('cable'),
            z.literal('cabinet'),
            z.literal('masterLock5441'),
            z.literal('masterLock4401'),
            z.literal('masterLock4400'),
            z.literal('danalock'),
        ]),
        lock_current_percentage: z.number(),
        products: z.array(z.object({ name: z.string(), image: ApiImageSchema.nullable() })),
    }),
);

const NewMessageCommentSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('NewMessageComment'),
        messageId: z.number(),
        comment_id: z.number(),
        comment_creator: z.number(),
        comment_content: z.string().nullable(),
        message_title: z.string(),
        content: z.string(),
        isImage: z.boolean(),
        isFile: z.boolean(),
    }),
);

const NewSurveySchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('NewSurvey'),
        survey_id: z.string(),
        survey_title: z.string(),
    }),
);

const ReminderSurveySchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('ReminderSurvey'),
        survey_id: z.string(),
        survey_title: z.string(),
    }),
);

const PushUnhandledCooperativeUserRegistrationSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('PushUnhandledCooperativeUserRegistration'),
        user_registration_count: z.number(),
        url: z.string(),
    }),
);

const ArrangerAddedSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('ArrangerAdded'),
        activity_id: z.number(),
        title: z.string(),
    }),
);

const ArrangerRemovedSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('ArrangerRemoved'),
        activity_id: z.number(),
        title: z.string(),
    }),
);

const ActivityRateHeimeSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('ActivityRateUs'),
        activity_id: z.number(),
    }),
);

const MessageExpiredSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('MessageExpiring'),
        message_id: z.number(),
        title: z.string(),
        active_until: z.string().nullable(),
    }),
);

const MessageSetExpiredSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('MessageSetExpired'),
        message_id: z.number(),
        title: z.string(),
        active_until: z.string().nullable(),
        actor_name: z.string().nullable(),
    }),
);

export const BookingNotificationSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('BookingNotification'),
        booking_id: z.union([z.number(), z.string()]),
        booking_start: z.string(),
        booking_end: z.string(),
        product_name: z.string(),
        time_to_send_type: z.union([z.literal('start'), z.literal('end')]),
        procedure_to_do: z.string().nullable(),
    }),
);

export const UserAddedToBookingSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('UserAddedToBooking'),
        booking_id: z.number().nullable(),
        booking_start: z.coerce.date(),
        booking_end: z.coerce.date(),
        product_name: z.string(),
        actor_name: z.string(),
    }),
);

export const UserRemovedFromBookingSchema = BaseNotificationSchema.merge(
    z.object({
        type: z.literal('UserRemovedFromBooking'),
        booking_id: z.number(),
        booking_start: z.coerce.date(),
        booking_end: z.coerce.date(),
        product_name: z.string(),
        actor_name: z.string(),
    }),
);

export const NotificationSchema = z.union([
    NewChatMessageSchema,
    SupportNotificationSchema,
    ActivityInviteSchema,
    ActivityCustomNotifySchema,
    TFBeforeActivtity,
    CancelledActivitySchema,
    UserGroupCreatedSchema,
    LockLowBatterySchema,
    NewMessageCommentSchema,
    NewSurveySchema,
    ReminderSurveySchema,
    PushUnhandledCooperativeUserRegistrationSchema,
    ArrangerAddedSchema,
    ArrangerRemovedSchema,
    ActivityRateHeimeSchema,
    MessageExpiredSchema,
    MessageSetExpiredSchema,
    BookingNotificationSchema,
    MessageCreatedSchema,
    SupportNoteSchema,
    SupportStatusSchema,
    UserAddedToBookingSchema,
    UserRemovedFromBookingSchema,
]);

export type Notification = z.infer<typeof NotificationSchema>;
