import React, { useState, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    LayoutChangeEvent,
    LayoutAnimation,
    StyleProp,
    TextStyle,
} from 'react-native';
import { captureException } from '_utils/Sentry';
import { subtitleFontSize } from '_utils/sizes';
import { RichText } from 'types/Base';
import RichTextView from './RichTextView';
import _fonts from '../../_fonts';
import { isWeb, Theme, useThemeStyle, WH, WW } from '../../_utils';
import Icon from '../Icon/Icon';

interface Props {
    text: RichText;
    /**
     * Event handler when pressing read more, if returning false, the read more will not be toggled
     */
    onReadMorePress?: () => boolean;
    numberOfLines?: number;
    style?: StyleProp<TextStyle>;
    fontSize?: number | string;
}

const ReadMoreRichText = ({
    text,
    onReadMorePress,
    numberOfLines = 6,
    style,
    fontSize = subtitleFontSize,
}: Props): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const { t } = useTranslation();
    const [showAllText, setShowAllText] = useState<boolean>(false);
    const [shouldShowReadMore, setShouldShowReadMore] = useState<boolean>(false);

    const actualFontSize = useMemo(() => {
        if (isWeb()) {
            if (typeof fontSize === 'string' && fontSize.toLowerCase().endsWith('rem')) {
                return remToPixels(fontSize);
            } else if (typeof fontSize === 'string' && /^[\d.]+$/.test(fontSize)) {
                return parseFloat(fontSize);
            } else if (typeof fontSize === 'number') {
                return fontSize;
            } else {
                captureException(new Error('Invalid fontSize:' + fontSize));
                // We cast and hope for the best
                return fontSize as unknown as number;
            }
        }
        return fontSize as number;
    }, [fontSize]);

    const handleLayout = (event: LayoutChangeEvent) => {
        if (!shouldShowReadMore) {
            if (event.nativeEvent.layout.height > actualFontSize * numberOfLines) {
                setShouldShowReadMore(true);
            }
        }
    };

    const handlePress = () => {
        if (onReadMorePress) {
            if (!onReadMorePress()) {
                return;
            }
        }
        setShowAllText((curr) => !curr);
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    };

    const maybeRenderReadMore = () => {
        if (shouldShowReadMore) {
            if (!showAllText) {
                return (
                    <TouchableOpacity onPress={handlePress} style={themedStyle.viewMoreWrapper}>
                        <Text style={themedStyle.viewMoreText}>{t('global:show_more')}</Text>
                        <Icon name="chevron" color="main" style={themedStyle.chevronDown} />
                    </TouchableOpacity>
                );
            } else {
                return (
                    <TouchableOpacity onPress={handlePress} style={themedStyle.viewMoreWrapper}>
                        <Text style={themedStyle.viewMoreText}>{t('global:show_less')}</Text>
                        <Icon name={'chevron'} color="main" style={themedStyle.chevronUp} />
                    </TouchableOpacity>
                );
            }
        }
        return null;
    };

    return (
        <>
            <View
                onLayout={handleLayout}
                style={{
                    maxHeight: shouldShowReadMore && !showAllText ? actualFontSize * numberOfLines - 1 : undefined,
                    overflow: 'hidden',
                }}
            >
                <View>
                    <RichTextView content={[text]} style={[style, { fontSize: actualFontSize }]} />
                </View>
            </View>
            {maybeRenderReadMore()}
        </>
    );
};

function remToPixels(remValue: string): number {
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const remNumber = parseFloat(remValue); // Extract the numeric value from the rem string
    return remNumber * rootFontSize;
}

const styles = (theme: Theme) =>
    StyleSheet.create({
        chevronUp: { marginLeft: WW * 0.02, transform: [{ rotate: '-90deg' }] },
        chevronDown: { marginLeft: WW * 0.02, transform: [{ rotate: '90deg' }] },
        viewMoreWrapper: {
            alignItems: 'center',
            marginTop: WH * 0.01,
            marginBottom: WH * 0.01,
            flexDirection: 'row',
        },
        viewMoreText: { fontFamily: _fonts.primaryFont, fontSize: WH * 0.025, color: theme.main },
    });

export default ReadMoreRichText;
