import React, { ReactElement, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { BottomSheetModal, BottomSheetTextInput } from '@gorhom/bottom-sheet';
import { FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { screenMargin, smallestMargin, subtitleFontSize } from '_utils/sizes';
import { ArrayElement } from 'types/Utility';
import CheckBox from './CheckBox';
import ControlledInput from './ControlledInput';
import EmptyMessage from './EmptyMessage';
import HeimeText from './HeimeText';
import Icon from './Icon/Icon';
import InputLabel from './InputLabel';
import Loader from './Loader';
import BottomSheet from './Modal/Bottomsheet';
import SecondaryButton from './SecondaryButton';
import SelectorChip from './SelectorChip';
import _fonts from '../_fonts';
import { isiOS, useThemeStyle, WH, WW } from '../_utils';
import { Theme, ThemeContext } from '../_utils/themeContext';

interface FormMultiSelect<Schema extends FieldValues, SchemaPath extends Path<Schema> & keyof Schema> {
    items: { id: ArrayElement<Schema[SchemaPath]>; text: string }[];
    name: SchemaPath;
    label: string;
    initialGrey?: boolean;
    isLoading?: boolean;
    placeholder: string;
    onRefresh(): void;
    isRefreshing: boolean;
}
const FormDropdown = function <Schema extends FieldValues, SchemaPath extends Path<Schema> & keyof Schema>({
    items,
    name,
    label,
    placeholder,
    isLoading,
    onRefresh,
    isRefreshing,
    initialGrey = false,
}: FormMultiSelect<Schema, SchemaPath>): ReactElement {
    const { t } = useTranslation();
    const themedStyle = useThemeStyle(dropdownStyles);
    const { theme } = useContext(ThemeContext);
    const { field } = useController<Schema>({ name });
    const selectedItems = items.filter((item) => field.value.includes(item.id));
    const mappedSelected = selectedItems.map((item) => item.id);
    const [changed, setChanged] = useState(false);
    const [search, setSearch] = useState('');

    const getOnChangeHandler = (itemId: ArrayElement<Schema[SchemaPath]>) => () => {
        setChanged(true);
        if (mappedSelected.includes(itemId)) {
            field.onChange(mappedSelected.filter((item) => item !== itemId));
        } else {
            field.onChange([...mappedSelected, itemId]);
        }
    };

    const bottomSheetModalRef = useRef<BottomSheetModal>(null);

    const handlePresentModalPress = useCallback(() => {
        bottomSheetModalRef.current?.present();
        setSearch('');
    }, []);

    const filteredElements = useMemo(
        () => items.filter((arg) => arg.text.toLowerCase().includes(search.toLowerCase())),
        [items, search],
    );
    return (
        <>
            <InputLabel>{label}</InputLabel>
            <TouchableOpacity
                onPress={handlePresentModalPress}
                style={[
                    themedStyle.selected,
                    {
                        borderColor: field.value && (!initialGrey || changed) ? theme.main : theme.secondaryLight,
                    },
                ]}
            >
                <HeimeText style={themedStyle.selectedText}>{placeholder}</HeimeText>
                <Icon style={themedStyle.chevron} name={'chevron'} color="black" />
            </TouchableOpacity>
            <BottomSheet
                ref={bottomSheetModalRef}
                enableDynamicSizing
                onRefresh={onRefresh}
                isRefreshing={isRefreshing}
                top={
                    <ControlledInput
                        leftImage={<Icon name="search" color="secondaryText" />}
                        // We opt out on android
                        inputComponent={isiOS() ? BottomSheetTextInput : undefined}
                        placeholder={t('global:search')}
                        autoFocus
                        onChange={(arg) => setSearch(arg ?? '')}
                        value={search}
                    />
                }
                content={
                    <View style={{ flex: 1, minHeight: WH * 0.35 }}>
                        {!search ? <EmptyMessage message={t('sharingNew:chooseApartmentGroupsDesc')} /> : null}
                        {isLoading ? (
                            <Loader />
                        ) : filteredElements.length === 0 ? (
                            <EmptyMessage message={t('global:noSearchResult')} />
                        ) : (
                            filteredElements?.map(({ id, text }) => (
                                <CheckBox
                                    checked={mappedSelected.includes(id)}
                                    onPress={getOnChangeHandler(id)}
                                    title={text}
                                    titleStyle={{ fontSize: subtitleFontSize }}
                                    key={id + ''}
                                />
                            ))
                        )}
                    </View>
                }
                bottom={
                    <SecondaryButton
                        onPress={() => bottomSheetModalRef.current?.dismiss()}
                        text={t('global:close')}
                        style={themedStyle.bottomMargin}
                    />
                }
                bottomHeight={WW * 0.05 + WH * 0.03 + smallestMargin * 2}
            />
            {selectedItems.length > 0 ? (
                <View style={themedStyle.selection}>
                    {selectedItems.map((item) => (
                        <SelectorChip
                            onSelect={getOnChangeHandler(item.id)}
                            key={item.id + ''}
                            content={
                                <>
                                    <HeimeText> {item.text}</HeimeText>
                                    <Icon name="cross" color="main" />
                                </>
                            }
                            variant="lightPrimary"
                            containerStyle={themedStyle.selectedItem}
                        />
                    ))}
                </View>
            ) : null}
        </>
    );
};

const dropdownStyles = (theme: Theme) =>
    StyleSheet.create({
        selected: {
            flexDirection: 'row',
            paddingTop: WW * 0.02,
            paddingBottom: WW * 0.02,
            paddingLeft: WW * 0.02,
            paddingRight: WW * 0.02,
            alignItems: 'center',
            borderWidth: 1.5,
            borderRadius: 4,
        },
        selectedText: {
            flex: 1,
            fontFamily: _fonts.primaryFont,
            color: theme.secondaryLight,
            fontSize: WW * 0.04,
        },
        chevron: { transform: [{ rotate: '90deg' }] },
        menu: {
            width: WW * 0.92,
            backgroundColor: theme.mainBackground,
        },
        menuText: {
            color: theme.black,
            fontFamily: _fonts.primaryFont,
            fontSize: WW * 0.04,
            marginTop: WW * 0.02,
            marginBottom: WW * 0.02,
        },
        selection: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: smallestMargin,
            gap: smallestMargin,
        },
        selectedItem: { marginRight: 0 },
        bottomMargin: { marginBottom: screenMargin },
    });

export default FormDropdown;
