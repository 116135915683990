import React, { ReactElement, useEffect, useMemo } from 'react';
import { useGetNotifications } from '_api/useNotifications';
import { setBadgeCount } from '_dependencies/notifee';
import useShouldUpdateApp from '_utils/hooks/useShouldUpdateApp';
import UnreadBadge from 'Components/UnreadBadge';

const NotificationCountBadge = (): ReactElement | null => {
    const { data: notifications } = useGetNotifications();
    const showAppBadge = useShouldUpdateApp();

    const unreadCount = useMemo(
        () =>
            (notifications?.pages ?? [])
                .reduce((curr, page) => {
                    if (page) {
                        curr.push(...page);
                    }
                    return curr;
                }, [])
                .filter(({ seen }) => seen === false).length + (showAppBadge ? 1 : 0),
        [notifications?.pages, showAppBadge],
    );

    useEffect(() => {
        setBadgeCount(unreadCount);
    }, [unreadCount]);

    if (unreadCount === 0) {
        return null;
    }

    return <UnreadBadge top={-5} right={-5} variant="small" count={unreadCount} />;
};

export default NotificationCountBadge;
