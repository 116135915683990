import React, { ReactElement, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { useAppNavigation } from '_navigator';
import { useKeyboardIsVisible, WH, WW } from '_utils';
import { useBottomSpacer } from '_utils/hooks';
import { screenMargin } from '_utils/sizes';
import { EmptyMessage, QueryView } from 'Components';
import { QueryViewProps } from 'Components/QueryViews/QueryView';
import { Message } from 'types/message';
import MessageItem from './MessageItem/MessageItem';

interface MessageListViewProps
    extends Omit<
        QueryViewProps<Message>,
        'renderItem' | 'style' | 'containerStyle' | 'columnWrapperStyle' | 'emptyList'
    > {
    emptyText: string;
    showActionButton: boolean;
    actionButton?: ReactElement;
}

const MessageListView = ({
    emptyText,
    numColumns,
    showActionButton,
    actionButton,
    ...props
}: MessageListViewProps): ReactElement => {
    const { navigate } = useAppNavigation();
    const keyboardIsVisible = useKeyboardIsVisible();
    const paddingBottom = useBottomSpacer();

    const renderItem = useCallback(
        ({ item }: { item: Message }) => {
            const onPress = (expanded: boolean) => {
                navigate('SharingSelected', { messageId: item.id, expanded: expanded ? 'true' : 'false' });
            };
            return <MessageItem item={item} onPress={onPress} />;
        },
        [navigate],
    );

    return (
        <>
            <QueryView
                {...props}
                renderItem={renderItem}
                style={styles.verticalPadding}
                numColumns={numColumns}
                containerStyle={[
                    numColumns && numColumns > 1 ? styles.containerStyleColumns : undefined,
                    { paddingBottom },
                ]}
                columnWrapperStyle={numColumns && numColumns > 1 ? styles.columnWrapperStyle : undefined}
                emptyList={
                    <View style={styles.emptyContainer}>
                        <EmptyMessage message={emptyText} />
                    </View>
                }
            />
            {keyboardIsVisible || !showActionButton ? null : actionButton}
        </>
    );
};

const styles = StyleSheet.create({
    buttonsContainer: {
        gap: screenMargin,
        marginTop: WH * 0.01,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    buttonsContainerColumns: {
        marginRight: screenMargin,
        marginLeft: screenMargin,
    },
    verticalPadding: {
        paddingTop: WW * 0.02,
        paddingBottom: WW * 0.02,
    },
    containerStyleColumns: {
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
    },
    columnWrapperStyle: {
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },
    emptyContainer: {
        marginTop: screenMargin,
        marginRight: screenMargin,
        marginBottom: screenMargin,
        marginLeft: screenMargin,
    },
});

export default MessageListView;
