import React, { ReactElement, useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { StyleProp, StyleSheet, View, ViewStyle, TouchableOpacity, LayoutAnimation } from 'react-native';
import { WW } from '_utils';
import ControlledInput from './ControlledInput';
import Icon from './Icon/Icon';
import IconButton from './IconButton';

interface ExpandableSearchInputProps {
    value: string;
    onChange(text: string): void;
    buttonText: string;
    inputStyle?: StyleProp<ViewStyle>;
}

const ExpandableSearchInput = ({
    onChange,
    value,
    buttonText,
    inputStyle,
}: ExpandableSearchInputProps): ReactElement => {
    const [expanded, setExpanded] = useState(value !== '');

    useFocusEffect(
        useCallback(() => {
            return () => {
                onChange('');
                setExpanded(false);
            };
        }, [onChange]),
    );

    if (expanded) {
        return (
            <ControlledInput
                leftImage={<Icon name="search" color="secondaryText" />}
                onChange={onChange}
                value={value}
                containerStyle={(style.searchContainer, inputStyle)}
                placeholder={buttonText}
                autoFocus
                rightImage={
                    value ? (
                        <TouchableOpacity
                            onPress={() => onChange('')}
                            pressRetentionOffset={{ top: 20, bottom: 20, right: 20, left: 40 }}
                            style={style.removeButton}
                        >
                            <Icon name="cross" color="mediumGrey" />
                        </TouchableOpacity>
                    ) : undefined
                }
            />
        );
    }

    const handleExpand = () => {
        LayoutAnimation.configureNext({
            duration: 200,
            create: { type: 'spring', property: 'scaleX', springDamping: 0.8 },
        });
        setExpanded(true);
    };

    return (
        <View style={style.buttonContainer}>
            <IconButton text={buttonText} icon="search" onPress={handleExpand} />
        </View>
    );
};

const style = StyleSheet.create({
    buttonContainer: {
        alignSelf: 'flex-end',
    },
    searchContainer: {
        flexGrow: 1,
        paddingTop: WW * 0.02,
        paddingBottom: WW * 0.02,
    },
    removeButton: {
        flexGrow: 0,
        flexBasis: 40,
        display: 'flex',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginLeft: -10,
    },
});

export default ExpandableSearchInput;
