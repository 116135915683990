import { z } from 'zod';
import { ApiImageSchema, FileLinkSchema, RichTextSchema } from './Base';

export enum MessageType {
    'Products' = 0,
    'Helpers' = 1,
    'Requests' = 2,
    'ByBoard' = 3,
}

const BaseMessageSchema = z.object({
    id: z.number(),
    created_at: z.number(),
    creator_id: z.number(),
    title: z.string(),
    richtext_content: RichTextSchema.nullable(),
    cooperative_group_id: z.number().nullable(),
});

const SharingMessageSchema = BaseMessageSchema.merge(
    z.object({
        type: z.union([z.literal(MessageType.Helpers), z.literal(MessageType.Products)]),
        pictures: z.array(ApiImageSchema),
        readUsers: z.array(z.number()),
    }),
);

const CommentSchema = z.object({
    id: z.number().nullable(),
    creator: z.number(),
    content: z.string().nullable(),
    created_at: z.number(),
    files: z.array(FileLinkSchema),
    edited: z.boolean(),
    deleted: z.boolean(),
});

const NoticeMessageSchema = BaseMessageSchema.merge(
    z.object({
        type: z.union([z.literal(MessageType.ByBoard), z.literal(MessageType.Requests)]),
        subscribers: z.array(z.number()),
        comments: z.array(CommentSchema),
        allow_comments: z.boolean(),
        unreadUsers: z.array(z.number()),
        send_notification: z.boolean(),
        files: z.array(FileLinkSchema),
        apartment_groups: z.array(z.object({ name: z.string(), id: z.string() })),
        active_until: z.coerce.date().nullable(),
    }),
);

export type SharingMessage = z.infer<typeof SharingMessageSchema>;

export type NoticeMessage = z.infer<typeof NoticeMessageSchema>;

export const MessageSchema = z.union([SharingMessageSchema, NoticeMessageSchema]);

export type Message = z.infer<typeof MessageSchema>;

export const isNoticeMessage = (message: Message): message is NoticeMessage => {
    return [MessageType.ByBoard, MessageType.Requests].includes(message.type);
};
