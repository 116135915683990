import { useContext } from 'react';
import { WW } from '_utils';
import { ThemeContext } from '_utils/themeContext';

export const borderWidth = 1.5;
export const borderRadius = 4;
export const fontSize = WW * 0.04;
export const padding = WW * 0.02;

export const useInputPropColors = () => {
    const { theme } = useContext(ThemeContext);
    return {
        placeholderTextColor: theme.secondaryLight,
        cursorColor: theme.main,
        selectionColor: theme.main,
    };
};
